import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Form, Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { userRegister } from "../redux/actions/userAuth";
import { Encryptedid } from "../utils/BcruptEncyptid";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { Button, InputGroup, Modal } from "react-bootstrap";
export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [moveOn, setMoveOn] = useState();

  function onChange(value) {
    setMoveOn(value);
    console.log("Captcha value:", value);
  }
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
      </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Become a SalonRated Salon
                  </h2>
                  <p>
                    Becoming SalonRated ensures that every potential customer
                    can trust that you're fully insured, certified, and
                    committed to providing exceptional professional hair and
                    beauty services. This instills complete confidence in your
                    business.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="sign-up-details-box">
                  <div className="pagination-tab">
                    <div className="tab-dots">
                      <ul>
                        <li className="active-dot">
                          <p>Step 1</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 2</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 3</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 4</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 5</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 6</p>
                        </li>
                      </ul>
                    </div>
                    <ProgressBar now={0} />
                  </div>
                  <h2 className="text-center choose-plan-title">
                    ACCOUNT HOLDER DETAILS
                  </h2>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      full_name: "",
                      surname: "",
                      email: "",
                      phone_number: "",
                      password: "",
                    }}
                    validate={(values) => {
                      const errors = {};

                      if (!values.full_name) {
                        errors.full_name = "Required*";
                      }
                      if (!values.surname) {
                        errors.surname = "Required*";
                      }

                      if (!values.phone_number) {
                        errors.phone_number = "Required*";
                      }

                      if (!values.email) {
                        errors.email = "Required*";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.password) {
                        errors.password = "Required*";
                      } else if (values.password.length <= 5) {
                        errors.password = "Password should be 6+ characters";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      if (!moveOn) {
                        return toast.error("Please verify before signup.");
                      }

                      dispatch(userRegister(values)).then((data) => {
                        if (data?.payload?.success) {
                          resetForm();
                          //navigate("/signin")
                          navigate(
                            `/signuptwo/${Encryptedid(
                              data?.payload?.data?._id
                            )}`
                          );
                        }
                        setSubmitting(false);
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={(e) => {
                          // setValidateValue(true);
                          handleSubmit(e);
                        }}
                      >
                        <Row className="mt-5">
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="full_name"
                                onChange={handleChange}
                                value={values.full_name}
                              />
                              <span className="formik-errors">
                                {errors.full_name &&
                                  touched.full_name &&
                                  errors.full_name}
                              </span>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Surname</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="surname"
                                onChange={handleChange}
                                value={values.surname}
                              />
                              <span className="formik-errors">
                                {errors.surname &&
                                  touched.surname &&
                                  errors.surname}
                              </span>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Type Here..."
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                              />
                              <span className="formik-errors">
                                {errors.email && touched.email && errors.email}
                              </span>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="phone_number"
                                onChange={handleChange}
                                value={values.phone_number}
                              />
                              <span className="formik-errors">
                                {errors.phone_number &&
                                  touched.phone_number &&
                                  errors.phone_number}
                              </span>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Password</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Type Here..."
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                              />
                              <span className="formik-errors">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </span>
                            </div>
                          </Col>
                          <Col
                            lg={12}
                            className="d-flex justify-content-center"
                          >
                            <ReCAPTCHA
                              sitekey="6Lc_vN4pAAAAAH8tzVGeoYexs4bkTA0RO68FG_Z3"
                              onChange={onChange}
                            />
                          </Col>
                          <Col lg={12}>
                            <div className="Salon-Signup-btn">
                              <button type="submit">Continue</button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sign Up Main Text end */}

        {/* footer section start  */}

        {/* footer section end  */}

        <Modal show={show} onHide={handleClose} className="popup-center-dspy">
          <div className="modal-set-srch">
            {/* <div className="modal-cls-icon">
              <svg
                onClick={handleClose}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.1003 0.000888237C14.3168 0.0107536 14.5302 0.0931082 14.6914 0.238087C14.943 0.464562 15.0557 0.8343 14.9734 1.16243C14.8915 1.49013 14.6425 1.74578 14.4038 1.98469C12.5628 3.8248 10.7185 5.66191 8.87155 7.49602C10.7159 9.3357 12.5603 11.175 14.4051 13.0146C14.6395 13.2484 14.883 13.4976 14.9678 13.8176C15.0557 14.1483 14.946 14.5236 14.6939 14.7548C14.4419 14.986 14.0583 15.0628 13.7368 14.9461C13.4775 14.8522 13.2735 14.6506 13.0789 14.455C11.2247 12.593 9.37089 10.731 7.51668 8.86902C5.67789 10.7108 3.83868 12.5527 1.99989 14.3945C1.75944 14.6356 1.50141 14.8865 1.17094 14.9676C0.841759 15.0486 0.471858 14.9332 0.246832 14.6793C0.0218061 14.4254 -0.0484878 14.0441 0.0715262 13.7267C0.164537 13.48 0.355702 13.2853 0.541724 13.0983C2.40408 11.2269 4.27287 9.36229 6.14895 7.50459C4.28873 5.64818 2.42894 3.79134 0.568727 1.93493C0.432854 1.79896 0.30041 1.65742 0.192826 1.49743C0.0912428 1.34601 0.0102333 1.17358 0.000803665 0.988715C-0.00133944 0.943677 0.000803665 0.89821 0.00723298 0.853173C0.0128051 0.814998 0.0303785 0.781112 0.038951 0.743795C0.0560958 0.670448 0.0830989 0.600104 0.118675 0.534049C0.190683 0.401509 0.295695 0.287843 0.418281 0.20077C0.541724 0.113268 0.684026 0.051502 0.832758 0.0244794C0.980632 -0.00254321 1.13494 0.00517754 1.27681 0.0545045C1.28452 0.0570781 1.29267 0.0600806 1.30038 0.0630831C1.54641 0.157877 1.74058 0.34918 1.92703 0.535764C3.79239 2.40247 5.65775 4.26917 7.52311 6.13545C9.37132 4.25673 11.2303 2.38831 13.0995 0.531046C13.2902 0.341459 13.4899 0.146296 13.7424 0.0540756C13.8573 0.0128983 13.9794 -0.00425893 14.1003 0.000888237Z"
                  fill="#071523"
                />
              </svg>
            </div> */}
            <Modal.Body className="px-0">
              <h3 className="RecifeDisplay">Just so you know..</h3>
              <div className="register-popup mt-3 ">
                <p>
                  {" "}
                  Currently we can only accept registrations from the United
                  Kingdom. If your business is located abroad, please contact
                  us.
                </p>
              </div>
              <div className="register-pop-btn">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="mt-2 "
                >
                  Close
                </Button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </Layout>
    </>
  );
}
