import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";

export default function UserHelpSupport() {
  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Help & Support start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Help & Support
                  </h2>
                  <p>
                    Welcome to our Help & Support section! This dedicated area
                    is tailored to address any questions you may have regarding
                    salon visits and industry standards in the hair and beauty
                    sector.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="sign-up-details-box">
                  <div className="">
                    <div className="drop-us-line">
                      <h2>Drop Us a Line</h2>
                      <p>
                        Please feel free to leave us a message, and we will
                        respond as soon as we can. We look forward to hearing
                        from you!
                      </p>
                    </div>
                    <Row>
                      <Col lg={6}>
                        <div className="sign-detail-input">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="sign-detail-input">
                          <label>Surname</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Surname"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="sign-detail-input">
                          <label>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="sign-detail-input">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="profile-descri">
                          <textarea
                            class="form-control"
                            id=""
                            rows="6"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="drop-us-submit">
                          <button type="submit">Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Help & Support end */}

        {/* Help & Support Text view start  */}

        <section className="support-view-section">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="support-view-left">
                  <img
                    className="white-logo"
                    src={require("../assets/images/support-view-left.webp")}
                  />
                </div>
              </Col>
              <Col lg={6} className="d-flex align-items-center">
                <div className="support-view-right">
                  <p>
                    If you need any help or assistance, please don't hesitate to
                    reach out to us via the form or our LIVE chat, and we'll get
                    back to you as soon as possible.
                  </p>
                  <p>
                    For contacting a salon, you can find their details through
                    the SalonRated Listings.
                  </p>
                  <p>
                    Please note that during busy periods, it may take us a
                    little longer than usual to reply, but rest assured, we will
                    respond as soon as possible.
                  </p>
                  <p>SalonRated Team</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Help & Support Text view End */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
