import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import LoginLayout from "../Layout/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews } from "../redux/actions/adminActions";
import Pagination from "../Components/Pagination";
import { Rating } from "react-simple-star-rating";

export default function Reviews() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reviews, totalReviews } = useSelector((state) => ({
    reviews: state.userMgmtSlice.reviews,
    totalReviews: state.userMgmtSlice.totalReviews,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    dispatch(
      getAllReviews({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 1,
        salonId: sessionStorage.getItem("userId")
      })
    );
  }, [page, limit, search]);

  console.log(reviews, totalReviews, "reviews, totalReviews");
  return (
    <>
      <LoginLayout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Careers start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">Reviews</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={10} className="mx-auto">
                <div className="review-box">
                  <Row>
                    <Col lg={12} className="mb-4">
                      <div className="salon-logo-detials">
                        <h2 className="mt-0">
                          Read what you clients say about you
                        </h2>
                        <div className="salon-statics">
                          <div className="salon-statics-icon">
                            <img
                              src={
                                require("../assets/images/client-star.svg")
                                  .default
                              }
                            />
                          </div>
                          <h3 className="RecifeDisplay">{totalReviews}</h3>
                          <p className="Futura-Lt-BT">Total Reviews</p>
                        </div>
                      </div>
                    </Col>
                    {
                      reviews?.map((item) => {
                        return (
                          <Col lg={6}>
                            <Link to={`/reviewdetails/${item?._id}`} className="review-card">
                              <div className="review-card-text">
                                <div className="review-card-name">
                                  <p>{item?.name}</p>
                                </div>
                                <p>{item?.address}</p>
                                <div className="review-star">
                                <Rating initialValue={item?.rating} />

                                </div>
                                <p className="Futura-Lt-BT">
                                  {item?.description || "N/A"}
                                </p>
                              </div>
                            </Link>
                          </Col>
                        )
                      })
                    }


                    <Col lg={12}>
                      <Pagination
                        class="next-btn-new"
                        totalStuff={totalReviews}
                        limit={limit}
                        setPage={setPage}
                      />
                      {/* <div className="pagination-box mt-4">
                        <ul>
                          <li>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <rect
                                  width="32"
                                  height="32"
                                  rx="4"
                                  fill="#B9B5AB"
                                />
                                <path
                                  d="M19.1562 11.41L14.5763 16L19.1562 20.59L17.7463 22L11.7463 16L17.7463 10L19.1562 11.41Z"
                                  fill="white"
                                />
                              </g>
                            </svg>
                          </li>

                          <li>
                            <p className="active">1</p>
                          </li>
                          <li>
                            <p>2</p>
                          </li>
                          <li>
                            <p>3</p>
                          </li>
                          <li>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="31"
                                height="31"
                                rx="3.5"
                                fill="white"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="31"
                                height="31"
                                rx="3.5"
                                stroke="#DCDAD5"
                              />
                              <path
                                d="M12.8438 11.41L17.4237 16L12.8438 20.59L14.2537 22L20.2537 16L14.2537 10L12.8438 11.41Z"
                                fill="#DCDAD5"
                              />
                            </svg>
                          </li>
                        </ul>
                      </div> */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Careers end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </LoginLayout>
    </>
  );
}
