import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import {Helmet} from "react-helmet";
export default function OurStandard() {
  return (
    <>
     <Helmet>
        <title>Our Standard - Salon Rated</title>
        <meta name="description" content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Our Standard start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">Our Standard</h2>
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col lg={12}>
                <img
                  src={require("../assets/images/our-standard-banner.webp")}
                />
              </Col> */}
              <Col lg={12}>
                <div className="Terms-Use-box">
                  <img
                    src={require("../assets/images/our-standard-banner.webp")}
                  />
                  <p>
                    The SalonRated Standard outlines the industry benchmarks for
                  </p>
                  <p>
                    <b>Insurance and Licences</b> - Insurance must be held for
                    the treatments carried out within the salon Such as;
                  </p>
                  <p>
                    <b>Public Liability</b> - Insurance.
                  </p>
                  <p>
                    <b>Professional indemnity</b> - Insurance covering all the
                    treatments and/or services provided by the salon.
                  </p>
                  <p>
                    Relevant local authority licences (if required) to provide
                    certain beauty treatments, for example, electrolysis or sun
                    beds.
                  </p>

                  <p>
                    Appropriate licenses to cover activities such as playing
                    music, showing TV or serving alcohol.
                  </p>
                  <p>
                    <b>Qualifications and Training</b> - The salon should hold
                    accredited training certification. Salons will make sure
                    their team members have:
                  </p>
                  <p>
                    Qualifications from a recognised UK qualifications body* in
                    a relevant subject and at an appropriate level for the
                    service or treatment being offered, or equivalent
                    industry-recognised training and experience.
                  </p>

                  <p>
                    Industry-recognised training on the tools or equipment they
                    use.
                  </p>
                  <p>
                    *Listed on the Ofqual register{" "}
                    <Link to="https://register.ofqual.gov.uk">
                      https://register.ofqual.gov.uk
                    </Link>
                    (searchable by qualifications or organisations)
                  </p>

                  <p>
                    <b>Health and Safety</b> - The salon must have a valid
                    health and safety policy in place ensuring risk assessments
                    are carried out, this must also be reviewed regularly.
                  </p>
                  <p>Salons should make sure –</p>

                  <p>
                    Premises, tools and equipment are regularly safety tested
                    and maintained to ensure the health and safety of clients.
                  </p>
                  <p>
                    Rigorous hygiene and cleanliness is observed and reported.
                  </p>
                  <p>
                    Uniforms, other clothing, towels, gowns and linen are clean.
                  </p>
                  <p>
                    Equipment, such as clippers, tweezers, brushes and combs, is
                    cleaned, disinfected and sterilized as per local authority
                    guidelines.
                  </p>
                  <p>
                    Clinical waste (eg razors, needles) is disposed of in
                    specially marked containers.
                  </p>
                  <p>
                    Appropriate personal protective equipment is worn, for
                    example, gloves when providing services such as hair color,
                    shaving, pedicures or intimate waxing.
                  </p>
                  <p>
                    Appropriate protective equipment, such as gowns, is provided
                    for use while services or treatments are being carried out.
                  </p>
                  <p>
                    Instructions and guidance for the safe use of equipment,
                    materials and products is followed.
                  </p>
                  <p>
                    <b>Age restrictions</b> – A salon must apply age
                    restrictions on treatments.
                  </p>
                  <p>
                    Beauty treatments are not recommended for under-16s with the
                    following exceptions:
                  </p>
                  <p>Earlobe piercing (stud and gun method, one hole)</p>
                  <p>Waxing (not intimate waxing)</p>
                  <p>Facials</p>
                  <p>Manicures</p>
                  <p>Pedicures</p>
                  <p className="text-center">
                    Treatments must only be carried out on under-16s with
                    written consent from a parent or legal guardian. A parent or
                    responsible adult (eg carer) must be present.
                  </p>
                  <p>
                    Treatments should not be carried out on minors who are below
                    the minimum ages recommended by the NHF/NBF, especially for
                    permanent hair colour which is not intended for use on
                    under-16s and UV tanning which cannot be carried out for
                    under 18s (Sunbeds (Regulation) Act 2010 and the Sunbeds
                    (Regulation) Act 2010 (Wales) Regulations 2011).
                  </p>
                  <p>
                    <b>Equality & Diversity</b> – Accredited salons will make
                    sure:
                  </p>

                  <p>The welfare of any vulnerable clients is protected.</p>
                  <p>
                    All salon staff understand the different ways in which
                    clients may be vulnerable, including emotional
                    vulnerability, mental health conditions (including body
                    dysmorphia), learning difficulties, physical disability,
                    ageing and those whose first language isn’t English.
                  </p>
                  <p>
                    Extra time and effort is taken to make sure vulnerable
                    clients understand the treatments or services they have
                    requested and how much they will cost.
                  </p>
                  <p>
                    Treatments or services the salon considers inappropriate,
                    unethical or potentially unsafe are not provided.
                  </p>
                  <p>
                    Services should be adapted and marketed to allow
                    accessibility for all where possible.
                  </p>
                  <p>
                    <b>Allergy alert testing and skin sensitivity testing</b> –
                    Patch testing is a requirement on certain treatments which
                    may put clients at risk. Always refer to your manufactures
                    and insurance guidelines.
                  </p>
                  <p>
                    <b>Consultation</b> – A thorough consultation should be
                    carried out, including a health questionnaire for beauty
                    salon clients, and especially for new clients, to discuss
                    requirements before agreeing on the service or treatment to
                    be provided.
                  </p>

                  <p>
                    A thorough consultation is carried out, including a health
                    questionnaire for beauty salon clients, and especially for
                    new clients, to discuss requirements before agreeing on the
                    service or treatment to be provided.
                  </p>

                  <p>
                    Client consent to the beauty treatment being provided is
                    recorded.
                  </p>

                  <p>
                    <b>Terms of Service</b> – It is good practice for a salon to
                    define a terms of service and have this accessible for
                    clients to review prior to a treatment. This would cover
                    things such as – The salons refund policy, cancelation
                    policy, opening times, sale of products, loyalty scheme etc.
                    A template of a Terms of Service can be found on the Salon
                    Rated Hub.
                  </p>

                  <p>
                    <b>Data Protection</b> – As required by the General Data
                    Protection Regulation (GDPR), an accredited salon will
                    maintain accurate and up-to-date client records.
                  </p>

                  <p>
                    Client information will be kept confidential, securely
                    stored and only used for the purpose for which it was give
                  </p>

                  <p>
                    <b>Complaints</b> – Salons will: Have a complaints policy
                    which is available for clients to see
                  </p>
                  <p>
                    Take immediate action to make sure clients receive a quick
                    and fair response to
                  </p>
                  <p>
                    Any complaint and aim to resolve it within no more than 8
                    weeks.
                  </p>
                  <p>
                    Learn from complaints and identify opportunities to improve
                    services to clients.
                  </p>
                  <p>
                    Refer clients to Hair & Beauty Mediation, or another
                    certified Alternative Dispute Resolution (ADR)
                  </p>
                  <p>
                    Fully participate if the client wishes to use ADR and accept
                    the decisions made.
                  </p>
                  <p>
                    The above is inline with Trading Standards and NHF/NBF
                    guidelines. Read more{" "}
                    <Link to="https://www.tradingstandards.uk/media/documents/commercial/ccas-consultation/national-hairdressers-federation-code-of-practice.pdf">
                      here
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Our Standard end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
