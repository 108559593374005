import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/BcruptEncyptid";
import { Field, Formik } from "formik";
import { userEditProfile } from "../redux/actions/userAuth";
import { getSalonProfileById } from "../redux/actions/userActions";
import { Helmet } from "react-helmet";

export default function SignUpFour() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  var dcryptId = Decryptedid(atob(id));
  const { salonDetail } = useSelector((state) => ({
    salonDetail: state.userMgmtSlice.salonDetail,
  }));

  useEffect(() => {
    dispatch(getSalonProfileById({ id: dcryptId, admin: "noclicks" }));
  }, [dcryptId]);
  return (
    <>
          <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Become a SalonRated Salon
                  </h2>
                  <p>
                    Becoming SalonRated ensures that every potential customer
                    can trust that you're fully insured, certified, and
                    committed to providing exceptional professional hair and
                    beauty services. This instills complete confidence in your
                    business.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="sign-up-details-box">
                  <div className="pagination-tab">
                    <div className="tab-dots">
                      <ul>
                        <li className="active-dot">
                          <p>Step 1</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 2</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 3</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 4</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 5</p>
                        </li>
                        <li>
                          {" "}
                          <p>Step 6</p>
                        </li>
                      </ul>
                    </div>
                    <ProgressBar now={80} />
                  </div>
                  <h2 className="text-center choose-plan-title">
                    SOCIAL MEDIA
                  </h2>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      facebook_url: salonDetail?.facebook_url || "",
                      insta_url: salonDetail?.insta_url || "",
                      youtube_url: salonDetail?.youtube_url || "",
                      twitter_url: salonDetail?.twitter_url || "",
                      booking_url: salonDetail?.booking_url || "",
                      tiktok_url: salonDetail?.tiktok_url || "",
                      market_preferences:
                        salonDetail?.market_preferences || false,
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log(values, "values");
                      values.id = dcryptId;
                      values.step_status = 4;
                      dispatch(userEditProfile(values)).then((data) => {
                        if (data?.payload?.success) {
                          resetForm();
                          navigate(`/SignUpFive/${id}`);
                        }
                        setSubmitting(false);
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={(e) => {
                          // setValidateValue(true);
                          handleSubmit(e);
                        }}
                      >
                        <Row className="mt-5">
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Facebook URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="facebook_url"
                                onChange={handleChange}
                                value={values.facebook_url}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Instagram URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="insta_url"
                                onChange={handleChange}
                                value={values.insta_url}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Tiktok URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="tiktok_url"
                                onChange={handleChange}
                                value={values.tiktok_url}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Youtube URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="youtube_url"
                                onChange={handleChange}
                                value={values.youtube_url}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Twitter URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="twitter_url"
                                onChange={handleChange}
                                value={values.twitter_url}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="sign-detail-input">
                              <label>Booking URL</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here..."
                                name="booking_url"
                                onChange={handleChange}
                                value={values.booking_url}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="category-select" key="privacy">
                              <Field
                                class="form-check-input"
                                type="checkbox"
                                name="market_preferences"
                                value={values.market_preferences ? 1 : 0}
                                onChange={handleChange}
                                checked={values.market_preferences}
                              />
                              <label htmlFor="privacy">
                                I want to receive marketing emails from time to
                                time from SalonRated
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="Salon-Signup-btn">
                              <Link
                                to={`/signupthree/${id}`}
                                className="me-3 back-sign"
                              >
                                Back
                              </Link>
                              <button type="submit">Continue</button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sign Up Main Text end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
