import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-hot-toast";
import { Encryptedid } from "../../utils/BcruptEncyptid";



const userRegister = createAsyncThunk(
  "userRegister",
  async (signupObj) => {
    const { data } = await adminApi.post("/auth/register", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const userEditProfile = createAsyncThunk(
  "userEditProfile",
  async (signupObj) => {
    const { data } = await adminApi.post("/auth/editProfile", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const userUpdatePassword = createAsyncThunk(
  "userEditProfile",
  async (reqBody) => {
    const { data } = await adminApi.put("/update_password", reqBody);
    console.log("action", data)
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const login = createAsyncThunk(
  "login",
  async (loginObj) => {
    const { data } = await adminApi.post("/auth/login", loginObj);

    if (data?.success && (data?.data?.step_status == 5 || data?.data?.step_status == 0) && (data?.data?.subscriptionId || data?.data?.subscriptionId === null)) {
      sessionStorage.setItem("token", data?.data?.token);
      sessionStorage.setItem("userId", data?.data?._id);
      toast.success(data?.message);
      if (data?.data?.subscriptionId === null) {
        return window.location.href = "/yourplan";
      }
      window.location.href = "/dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
    else if (data?.data?.step_status == 1) {
      toast.error("Please complete your form first before further proceed.");
      window.location.href = `/signuptwo/${Encryptedid(data?.data?._id)}`;
    } else if (data?.data?.step_status == 2) {
      toast.error("Please complete your form first before further proceed.");
      window.location.href = `/signupthree/${Encryptedid(data?.data?._id)}`;
    } else if (data?.data?.step_status == 3) {
      toast.error("Please complete your form first before further proceed.");
      window.location.href = `/signupfour/${Encryptedid(data?.data?._id)}`;
    } else if (data?.data?.step_status == 4) {
      toast.error("Please complete your form first before further proceed.");
      window.location.href = `/SignUpFive/${Encryptedid(data?.data?._id)}`;
    }

  }
);

const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    const response = await adminApi.post("/auth/forgotPassword", data);
    return response.data;
  }
);

const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkApi) => {
    const response = await adminApi.put(`/auth/resetPass/${data.token}`, data);
    return response.data;
  }
);

export {
  userRegister,
  userEditProfile,
  login,
  forgotPassword,
  resetPassword,
  userUpdatePassword
};
