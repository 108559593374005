import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import { Formik } from "formik";
import { addSupport } from "../redux/actions/adminActions";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

export default function HelpSupport() {
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>Help & Support - Salon Rated</title>
        <meta
          name="description"
          content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK"
        />
      </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Help & Support start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Help & Support
                  </h2>
                  <p>
                    Welcome to our Help & Support section! This dedicated area
                    is tailored to address any questions you may have regarding
                    salon visits and industry standards in the hair and beauty
                    sector.
                  </p>
                </div>
              </Col>
            </Row>{" "}
            <Row>
              <Col lg={12}>
                <div className="sign-up-details-box">
                  <div className="">
                    <div className="drop-us-line">
                      <h2>Frequently Asked Questions</h2>
                      <p>
                        If you cannot find the answer, feel free to contact us
                        via email or phone.
                      </p>
                    </div>
                    <Row>
                      <Col lg={12}>
                        <div className="faq-question">
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                What should I look for when choosing a hair or
                                beauty salon?
                              </Accordion.Header>
                              <Accordion.Body>
                                When choosing a salon, consider factors like
                                cleanliness, the qualifications and experience
                                of the stylists or technicians, the range of
                                services offered, customer reviews and
                                recommendations, as well as the overall ambiance
                                of the salon. Additionally, enquire about the
                                products they use and their commitment to
                                customer satisfaction.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Is the hair and beauty industry regulated?
                              </Accordion.Header>
                              <Accordion.Body>
                                Yes, the hair and beauty industry is regulated
                                to ensure the safety and well-being of
                                customers. Regulations vary by region, but
                                typically include licensing requirements for
                                professionals, health and safety standards for
                                salon facilities, and guidelines for product
                                usage.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                What are some common red flags to watch out for
                                when visiting a salon?
                              </Accordion.Header>
                              <Accordion.Body>
                                Some common red flags include unclean or unkempt
                                salon facilities, unlicensed or inexperienced
                                staff, lack of transparency about pricing and
                                services, poor communication with clients, and
                                the use of outdated or unsafe equipment. If you
                                encounter any of these issues, it's best to seek
                                services elsewhere to ensure a positive and safe
                                experience.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                How can I ensure that the products used in the
                                salon are safe and suitable for me?
                              </Accordion.Header>
                              <Accordion.Body>
                                Prioritise salons that use high-quality,
                                reputable brands known for their safety and
                                effectiveness. You can also inquire about the
                                ingredients in the products used and discuss any
                                specific concerns or allergies you may have with
                                your stylist or technician. Additionally, look
                                for salons that conduct patch tests for certain
                                treatments to minimise the risk of adverse
                                reactions.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                What should I do if I'm dissatisfied with the
                                service I received at a salon?
                              </Accordion.Header>
                              <Accordion.Body>
                                If you're dissatisfied with the service you
                                received at a salon, it's important to
                                communicate your concerns directly with the
                                salon management or staff. Give them the
                                opportunity to address your issues and rectify
                                the situation.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                              <Accordion.Header>
                                How does a business being SalonRated benefit me
                                as a customer?
                              </Accordion.Header>
                              <Accordion.Body>
                                A SalonRated Listing is a sign of trust that
                                signifies that the salon has met rigorous
                                criteria to be listed on our platform. This
                                includes factors such as adherence to industry
                                regulations, high standards of cleanliness and
                                hygiene, skilled and qualified staff,
                                transparency in pricing and services, and a
                                commitment to customer satisfaction. By choosing
                                a SalonRated salon, you can trust that you're
                                selecting a reputable establishment that
                                prioritises your well-being and strives to
                                deliver an exceptional salon experience.
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Col>
                      <div className="sign-bottom-border"></div>
                    </Row>
                    <Row>
                      <Col lg={4}></Col>
                      <Col lg={4}></Col>
                      <Col lg={4}></Col>
                      <Col lg={4}></Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Help & Support end */}

        {/* Help & Support Text view start  */}

        <section className="support-view-section">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="support-view-left">
                  <img
                    className="white-logo"
                    src={require("../assets/images/support-view-left.webp")}
                  />
                </div>
              </Col>
              <Col lg={6} className="d-flex align-items-center">
                <div className="support-view-right">
                  <p>
                    If you need any help or assistance, please don't hesitate to
                    reach out to us via the form or our LIVE chat, and we'll get
                    back to you as soon as possible.
                  </p>
                  <p>
                    For contacting a salon, you can find their details through
                    the <Link to="/searchsalons">SalonRated Listings.</Link>
                  </p>
                  <p>
                    Please note that during busy periods, it may take us a
                    little longer than usual to reply, but rest assured, we will
                    respond as soon as possible.
                  </p>
                  <p>SalonRated Team</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Help & Support Text view End */}

        {/* Frequently Asked Questions start */}

        <section className="faq-box-section">
          <Container>
            {" "}
            <Row>
              <Col lg={12}>
                <div className="sign-up-details-box">
                  <div className="">
                    <div className="drop-us-line">
                      <h2>Drop Us a Line</h2>
                      <p>
                        Please feel free to leave us a message, and we will
                        respond as soon as we can. <br />
                        We look forward to hearing from you!
                      </p>
                    </div>
                    <Formik
                      initialValues={{
                        full_name: "",
                        surname: "",
                        email: "",
                        phone_number: "",
                      }}
                      validate={(values) => {
                        const errors = {};

                        if (!values.full_name) {
                          errors.full_name = "Required*";
                        }
                        if (!values.surname) {
                          errors.surname = "Required*";
                        }

                        if (!values.phone_number) {
                          errors.phone_number = "Required*";
                        }

                        if (!values.email) {
                          errors.email = "Required*";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }

                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        console.log(values, "comment");
                        dispatch(addSupport(values)).then((data) => {
                          if (data?.payload?.success) {
                            resetForm();
                            toast.success(data?.payload?.message);
                            //navigate(`/signuptwo/${Encryptedid(data?.payload?.data?._id)}`);
                          }
                          setSubmitting(false);
                        });
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form
                          onSubmit={(e) => {
                            // setValidateValue(true);
                            handleSubmit(e);
                          }}
                        >
                          <Row>
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="full_name"
                                  onChange={handleChange}
                                  value={values.full_name}
                                />
                                <span className="formik-errors">
                                  {errors.full_name &&
                                    touched.full_name &&
                                    errors.full_name}
                                </span>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Surname</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Surname"
                                  name="surname"
                                  onChange={handleChange}
                                  value={values.surname}
                                />
                                <span className="formik-errors">
                                  {errors.surname &&
                                    touched.surname &&
                                    errors.surname}
                                </span>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  onChange={handleChange}
                                  value={values.email}
                                />
                                <span className="formik-errors">
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </span>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Phone Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone Number"
                                  name="phone_number"
                                  onChange={handleChange}
                                  value={values.phone_number}
                                />
                                <span className="formik-errors">
                                  {errors.phone_number &&
                                    touched.phone_number &&
                                    errors.phone_number}
                                </span>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="profile-descri">
                                <textarea
                                  class="form-control"
                                  id=""
                                  rows="6"
                                  placeholder="Comment"
                                  name="comment"
                                  onChange={handleChange}
                                  value={values.comment}
                                ></textarea>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="drop-us-submit">
                                <button type="submit">Submit</button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Frequently Asked Questions End */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
