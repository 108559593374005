import MarkerClusterer from "@google/markerclustererplus";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const iconList = {
  icon1: "https://i.ibb.co/k3KhLz0/Group-33934.png",
  icon2:
    "https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png",
  icon3:
    "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Ball-Right-Azure.png",
  icon4:
    "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
};

const customStyles = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: "#ded6cb" }],
  },
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [{ weight: "2.00" }],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [{ color: "#9c9c9c" }],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ color: "#f2f2f2" }],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  // Add more custom styles as needed
];

const InitMap = ({ salonArr, lat, long }) => {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const navigate = useNavigate();

  console.log(lat, long);
  const markerList =
    salonArr?.length > 0
      ? salonArr?.map((item) => {
          return {
            lat: item?.lat,
            lng: item?.long,
            icon: iconList.icon1,

            business_name: item?.business_name,
            id: item?.salon_slug,
            profile_logo: item?.profile_logo,
          };
        })
      : [
          {
            lat: long,
            lng: lat,
            icon: iconList.icon1,
            business_name: "London",
          },
        ];

  const ukBounds = {
    north: 60.9, // Northernmost point of the UK
    south: 49.7, // Southernmost point of the UK
    east: 1.9, // Easternmost point of the UK
    west: -8.6, // Westernmost point of the UK
  };

  useEffect(() => {
    let map;

    const initMap = async () => {
      // Import 'Map' class from Google Maps library
      const { Map } = await window.google.maps.importLibrary("maps");
      // Create a new map instance
      map = new Map(document.getElementById("map"), {
        center: {
          lat: parseFloat(lat) || (ukBounds.north + ukBounds.south) / 2,
          lng: parseFloat(long) || (ukBounds.east + ukBounds.west) / 2,
        },
        zoom: 7,
        scrollwheel: false,
        styles: customStyles,
      });

        // Initialize MarkerClusterer
        const markerCluster = new MarkerClusterer(map, [], {
          imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });

      var defaultbounds = new window.google.maps.LatLngBounds(
        { lat: ukBounds.south, lng: ukBounds.west },
        { lat: ukBounds.north, lng: ukBounds.east }
      );
      var bounds = new window.google.maps.LatLngBounds();
      markerList?.map((x) => {
        const marker = createMarker(x, map);
        bounds.extend(marker.position);
        markerCluster.addMarker(marker);
      });
      if (
        markerList[0]?.business_name == "London" &&
        markerList[0]?.lat == undefined
      ) {
        console.log("defaultbounds");
        map.fitBounds(defaultbounds);
      } else if (markerList[0]?.business_name == "London") {
      } else {
        map.fitBounds(bounds);
      }
    };
    // create marker on google map
    const createMarker = (markerObj, googleMap) => {
      const marker = new window.google.maps.Marker({
        position: { lat: markerObj.lat, lng: markerObj.lng },
        map: googleMap,
        icon: {
          url: markerObj?.business_name == "London" ? "" : markerObj.icon,
          scaledSize: new window.google.maps.Size(38, 50),
        },
      });

      const contentString = `
        <div className="popup-show-content">
          <div style="display:flex;align-items:center;justify-content:center; "><img style="border-radius:40px;" src="${
            markerObj?.profile_logo
              ? BASE_URL + "/" + markerObj?.profile_logo
              : require("../../assets/images/band-logo-icon.png")
          }" alt="${markerObj.business_name}">
     
      </div>
          <h4 style="font-size:16px;text-align:center;">${
            markerObj.business_name
          }</h4 style="font-size:14px;">
        </div>
      `;

      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("mouseover", () => {
        infowindow.open(
          googleMap,
          markerObj?.business_name == "London" ? "" : marker
        );
      });

      marker.addListener("mouseout", () => {
        infowindow.close();
      });

      marker.addListener("click", () => {
        if (markerObj.id) {
          navigate(`/salon/${markerObj.id}`);
        }
      });

      return marker;
    };

    // Initialize Google Maps when API is loaded
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      initMap();
    }
  }, [salonArr, lat, long, navigate]);

  return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
};

export default React.memo(InitMap);
