import React from "react";
import LoginHeader from "./LoginHeader";
import Footer from "./Footer";

const LoginLayout = ({ children }) => {
  return (
    <>
      <LoginHeader />
      {children}
      <Footer />
    </>
  );
};
export default LoginLayout;
