import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-hot-toast";

const addSupport = createAsyncThunk("addSupport", async (notObj) => {
  const { data } = await adminApi.post("/addSupport", notObj);
  return data;
})

const getBlog = createAsyncThunk("getBlog", async ({ page, limit, search, status }) => {
  const { data } = await adminApi.get(`/getBlog?page=${page}&limit=${limit}&search=${search}&status=${status}`);
  return data;
});

const getBlogById = createAsyncThunk("getBlogById", async (id) => {
  const { data } = await adminApi.get(`/getBlogById?id=${id}`);
  return data;
});

const getAllReviews = createAsyncThunk("getAllReviews", async ({ page, limit, search, salonId }) => {
  const { data } = await adminApi.get(`/getAllReviews?page=${page}&limit=${limit}&search=${search}&salonId=${salonId}`);
  return data;
});

const getReviewById = createAsyncThunk("getReviewById", async (id) => {
  const { data } = await adminApi.get(`/getReviewById?id=${id}`);
  return data;
});

const createReview = createAsyncThunk("createReview", async (notObj) => {
  const { data } = await adminApi.post("/createReview", notObj);
  return data;
})

const getSubscriptionById = createAsyncThunk(
  'getSubscriptionById', async (id, thunkApi) => {
    try {
      const response = await adminApi.get(`/getSubscriptionById?id=${id}`);
      return response.data;
    } catch (error) {
      toast.error(error.message)
    }
  }
)


const checkAllSalonExpires = createAsyncThunk("checkAllSalonExpires", async () => {
  const { data } = await adminApi.get("/checkAllSalonExpires");
  return data;
});

const getStatics = createAsyncThunk("getStatics", async () => {
  const { data } = await adminApi.get("/getStatics");
  return data;
});

const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const dashboard = createAsyncThunk("dashboard", async () => {
  const { data } = await adminApi.get("/dashboard");
  return data;
});

const getUsers = createAsyncThunk(
  "getUsers",
  async ({ page, limit, search, user_type }) => {
    const { data } = await adminApi.get(
      `/getUsers?page=${page}&limit=${limit}&search=${search}&user_type=${user_type}`
    );
    return data;
  }
);



const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getUserDetails/?user_id=${id}`);
  return data;
});

const addProperty = createAsyncThunk("addProperty", async (notObj) => {
  const { data } = await adminApi.post("/createProperties", notObj);
  return data;
});

const imagesaveAction = createAsyncThunk("imagesaveAction", async (notObj) => {
  const { data } = await adminApi.post("/imagesave", notObj);
  return data;
});



const tempBannedAccount = createAsyncThunk(
  "tempBannedAccount",
  async (obj) => {
    const { data } = await adminApi.put("/tempBannedAccount", obj);
    return data;
  }
);
const tempBannedAccountCustomer = createAsyncThunk(
  "tempBannedAccountCustomer",
  async (obj) => {
    const { data } = await adminApi.put("/tempBannedAccount", obj);
    return data;
  }
);



const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (formdata) => {
    const { data } = await adminApi.put("/editUserDetails", formdata);
    return data;
  }
);

const getAllManageProperties = createAsyncThunk(
  "getAllManageProperties",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getPropertyList?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const getProperty = createAsyncThunk("getProperty", async (id) => {
  const { data } = await adminApi.get(`/getProperty?id=${id}`);
  return data;
});



const updateProperty = createAsyncThunk(
  "updateProperty",
  async (formdata) => {
    const { data } = await adminApi.post("/updateProperty", formdata);
    return data;
  }
);
const deleteProperty = createAsyncThunk("deleteProperty", async (id) => {
  const { data } = await adminApi.delete(`/deleteProperty?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const createService = createAsyncThunk("createService", async (obj) => {
  const { data } = await adminApi.post(`/createService`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getServiceList = createAsyncThunk(
  "getServiceList",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getServiceList?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);


const changeUserPass = createAsyncThunk("changeUserPass", async (passObj) => {
  const { data } = await adminApi.put("/changeUserPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


const getService = createAsyncThunk("getService", async (id) => {
  const { data } = await adminApi.get(`/getService?id=${id}`);
  return data;
});



const updateService = createAsyncThunk(
  "updateService",
  async (formdata) => {
    const { data } = await adminApi.put("/updateService", formdata);
    return data;
  }
);

const deleteService = createAsyncThunk("deleteService", async (id) => {
  const { data } = await adminApi.delete(`/deleteService?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


//******************OPENING HOURS******************************** *//

 const createOpeningHours = createAsyncThunk(
  "createOpeningHours",
  async (data, thunkAPI) => {
      const response = await adminApi.post("/createOpeningHours", data);
      return response.data;
  }
);


 const updateOpeningHours = createAsyncThunk(
  "updateOpeningHours",
  async (data, thunkAPI) => {
      const response = await adminApi.put("/updateOpeningHours", data);
      return response.data;
  }
);

 const fetchOpeningHours = createAsyncThunk(
  "fetchOpeningHours",
  async (id, thunkAPI) => {
      const response = await adminApi.get(`/fetchOpeningHours?id=${id}`);
      return response.data;
  }
);

//********************************END********************************* *//



export {
  createOpeningHours,
updateOpeningHours,
fetchOpeningHours,
  
  dashboard,
  getUsers,
  getUserDetails,
  changeUserPass,
  editUserDetails,
  getStatics,
  getSubscriptionById,
  createReview,
  getAllReviews,
  getReviewById,
  getBlog,
  getBlogById,

  getAllUsers,
  addProperty,
  getAllManageProperties,
  getProperty,
  updateProperty,
  deleteProperty,
  createService,
  getServiceList,
  getService,
  updateService,
  deleteService,
  tempBannedAccount,
  tempBannedAccountCustomer,
  imagesaveAction,
  addSupport,
  checkAllSalonExpires
};
