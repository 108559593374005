import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import LoginLayout from "../Layout/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { getSalonProfileById } from "../redux/actions/userActions";

export default function Statistics() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const dispatch = useDispatch();
  const { salonDetail, subscription_data } = useSelector((state) => ({
    salonDetail: state.userMgmtSlice.salonDetail,
    subscription_data: state.userMgmtSlice.subscription_data,
  }));

  useEffect(() => {
    dispatch(getSalonProfileById({id:sessionStorage.getItem("userId"), admin:"noclicks"}));
  }, []);

  console.log(subscription_data,"subscription_data");

  return (
    <>
      <LoginLayout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Careers start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">Statistics</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={10} className="mx-auto">
                <div className="Careers-box">
                  <Row className="justify-content-center">
                    <Col lg={12} className="mb-4">
                      <div className="salon-logo-detials">
                        <img
                          src={
                            salonDetail?.profile_logo
                              ? BASE_URL + "/" + salonDetail?.profile_logo
                              : require("../assets/images/band-logo-icon.png")
                          }
                        />
                        <h2>{salonDetail?.business_name}</h2>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="salon-statics">
                        <div className="salon-statics-icon">
                          <img
                            src={
                              require("../assets/images/dash-search-icon.svg")
                                .default
                            }
                          />
                        </div>
                        <h3 className="RecifeDisplay">{salonDetail?.search_appearances}</h3>
                        <p className="Futura-Lt-BT">
                          Search
                          <br /> Appearances
                        </p>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="salon-statics">
                        <div className="salon-statics-icon">
                          <img
                            src={
                              require("../assets/images/dash-view-icon.svg")
                                .default
                            }
                          />
                        </div>
                        <h3 className="RecifeDisplay">{salonDetail?.page_views}</h3>
                        <p className="Futura-Lt-BT">Page Views</p>
                      </div>
                    </Col>
                    {subscription_data?.subscriptionType == 3 || (salonDetail?.subscription == 3 && salonDetail?.subscriptionId == 'olduser') && (
                      <Col lg={4}>
                        <div className="salon-statics">
                          <div className="salon-statics-icon">
                            <img
                              src={
                                require("../assets/images/dash-click-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <h3 className="RecifeDisplay">{salonDetail?.booking_link_clicks} </h3>
                          <p className="Futura-Lt-BT">
                            Booking
                            
                            <br /> Link Clicks
                          </p>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Careers end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </LoginLayout>
    </>
  );
}
