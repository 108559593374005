import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getBlog, getBlogById } from "../redux/actions/adminActions";
import parse from "html-react-parser"
import draftToHtml from "draftjs-to-html";
import moment from "moment";

export default function BlogDetail() {

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <div></div>;
      }
    },
  };
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { blogDetails, blogs } = useSelector((state) => ({
    blogDetails: state.userMgmtSlice.blogDetails,
    blogs: state.userMgmtSlice.blogs,
  }));



  useEffect(() => {
    dispatch(getBlogById(params.id));
  }, [])


  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    dispatch(
      getBlog({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
        status:0
      })
    );
  }, [page, limit, search]);



  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Blog Main start */}

        <section className="Sign-main-section">
          <Container>
            <Row className="mb-0">
              <Col lg={12} className="px-0">
                <div className="blog-banner mb-0">
                  <img
                    className="black-logo"
                    src={
                      blogDetails?.image ?
                        BASE_URL + blogDetails?.image : require("../assets/images/blog-img.png")
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="single-blog">
              <Col lg={12}>
                <div className="aur-blog-box">
                  <Row>
                    <Col lg={8}>
                      <div className="blog-detail-tittle">
                        <h2 className="RecifeDisplay common-heading">
                          {blogDetails?.title}
                        </h2>
                        <p className="Futura-Lt-BT">{moment(blogDetails?.createdAt).format("Do MMMM") ||
                          "N/A"}</p>
                      </div>

                      <div className="media-body blog-content-html">
                        {blogDetails?.blog_content &&
                          parse(
                            draftToHtml(JSON.parse(blogDetails?.blog_content)),
                            options
                          )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="blog-side-part">
                        <div className="blog-side-title">
                          <p className="FuturaB">More like this</p>
                        </div>
                        {
                          blogs?.map((item) => {
                            return (
                              item?._id != blogDetails?._id &&
                              <>
                                <a href={`/blogdetail/${item?.blog_slug}`} className="side-blog-box">
                                  <img
                                    className=""
                                    src={
                                      item?.image ?
                                        BASE_URL + item?.image : require("../assets/images/blog-img.png")
                                    }

                                  />
                                  <div className="blog-text-card">
                                    <p className="Futura-Lt-BT mb-2">{moment(item?.createdAt).format("Do MMMM")}</p>
                                    <p className="blog-text mb-0">
                                      {item?.title}
                                    </p>
                                  </div>
                                </a>
                              </>
                            )
                          })
                        }


                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-5">
              <Col lg={6} md={6} className="d-flex justify-content-end">
                <Link to="" className="blog-Previous">
                  <img src={require("../assets/images/blog-slide.png")} />
                  <div className="blog-Previous-text">
                    <p>
                      <span>
                        <img
                          src={
                            require("../assets/images/previous-blog-icon.svg")
                              .default
                          }
                        />
                      </span>
                      <span>Previous</span>
                    </p>
                    <p className="card-text">
                      Autumnal Salon Social Media and Marketing Ideas
                    </p>
                  </div>
                </Link>
              </Col>

              <Col lg={6} md={6}>
                <Link to="" className="blog-Next">
                  <div className="blog-Next-text">
                    <p>
                      <span>Next</span>
                      <span>
                        <img
                          src={
                            require("../assets/images/next-blog-icon.svg")
                              .default
                          }
                        />
                      </span>
                    </p>
                    <p className="card-text">
                      Autumnal Salon Social Media and Marketing Ideas
                    </p>
                  </div>
                  <img src={require("../assets/images/blog-slide.png")} />
                </Link>
              </Col>
            </Row> */}
          </Container>
        </section>

        {/* Blog Main end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
