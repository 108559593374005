import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";

export default function ContactUs() {
  return (
    <>
       <Helmet>
        <title>Contact Us - Salon Rated</title>
        <meta name="description" content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="contact-us-banner">
          {/* top navbar start  */}

          {/* top navbar End  */}

          {/* Contact us section start  */}
          <Container>
            <Row>
              <Col lg={12}>
                <div className="contact-title-main">
                  <div className="contact-title">
                    <h2 className="RecifeDisplay common-heading">Contact</h2>
                    <p>
                      We'd love to connect with you! Below, you'll find all our
                      contact details. Looking forward to hearing from you soon.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* Contact us section end  */}
        </section>

        {/* Header section End */}

        {/* Contact us box start  */}

        <section className="contact-box">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="contact-card">
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <div className="contact-card-box">
                        <div className="icon-img">
                          <img
                            src={
                              require("../assets/images/contact-icon.svg")
                                .default
                            }
                          />
                        </div>
                        <h3 className="RecifeDisplay">Contact</h3>
                        <p className="Futura-Lt-BT">
                          Email: hello@salonrated.com
                          <br /> Telephone: 0114 303 5141
                        </p>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="contact-card-box">
                        <div className="icon-img">
                          <img
                            src={
                              require("../assets/images/hours-icon.svg").default
                            }
                          />
                        </div>
                        <h3 className="RecifeDisplay">Hours</h3>
                        <p className="Futura-Lt-BT">
                          Mon-Fri: 8 AM - 4 PM <br /> Sat-Sun: Closed
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Contact us box End  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
