import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import {Helmet} from "react-helmet";
export default function WhySalonRated() {
  return (
    <>
     <Helmet>
        <title>Why Salon Rated - Salon Rated</title>
        <meta name="description" content="Becoming Salon Rated signifies that as a business you are fully insured, certified and evidenced to follow exceptional professional hair and beauty services." />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Why become a Salon Rated Salon? start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Why become a SalonRated Salon?
                  </h2>
                  <p>
                    SalonRated status is a powerful declaration of your
                    commitment to excellence. It's a seal of approval,
                    reassuring potential clients that your business is fully
                    insured and certified. More than that, it serves as tangible
                    proof that you consistently deliver outstanding professional
                    hair and beauty services. Above all, this status plants a
                    seed of unwavering trust in your customers towards your
                    business, paving the way for a loyal client base.
                  </p>
                  <p className="mt-2">
                  Despite the platform's name, you don't need to own or work in a traditional salon to become a member. We recognise that salons can take many forms, from converted garages to purpose-built garden rooms and beyond.
                  </p>
                </div>
              </Col>
            </Row>
            <div className="why-salon-box">
              <Row>
                <Col lg={12}>
                  <div className="why-salon-listing">
                    <Row>
                      <Col lg={12}>
                        <div className="becoming-salon">
                          <div className="becoming-salon-title">
                            <h3 className="RecifeDisplay common-heading">
                              Achieving SalonRated status brings numerous
                              advantages and opportunities:
                            </h3>
                          </div>
                          <ul>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Competitive Edge: Sets your salon apart from
                              competitors, fostering uniqueness
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Genuine Reviews: Provides a platform for honest,
                              verified feedback, aiding in monitoring and
                              improving services
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Regulated Salon Finder: As the UK's first
                              regulated Salon Finder, facilitates easier
                              navigation for customers
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Cost-effective Marketing: Offers economical
                              marketing solutions, expanding your reach without
                              heavy spending
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Social Media Exposure: Featured salons are
                              promoted across SalonRated Social Platforms,
                              bolstering online presence
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              Recognition Opportunities: Participate in annual
                              events and win national awards to further enhance
                              salon reputation
                            </li>
                            <li>
                              <span>
                                <img
                                  src={
                                    require("../assets/images/why-tick.svg")
                                      .default
                                  }
                                />
                              </span>
                              In essence, SalonRated status elevates your salon,
                              fostering stronger connections with clientele.
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={12}>
                      <div className="drop-us-submit">
                        <button type="submit">Signup</button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        {/*  Why become a Salon Rated Salon? end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
