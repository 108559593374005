import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../redux/actions/adminActions";
import moment from "moment";
import Pagination from "../Components/Pagination";
import { Helmet } from "react-helmet";

const options = {
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,

  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 5,
    },
  },
};

const optionsNew = {
  responsiveClass: true,
  nav: true,
  dots: true,
  autoplay: false,
  smartSpeed: 1000,

  margin: 30,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1.5,
    },
  },
};

const optionsNewslider = {
  responsiveClass: true,
  nav: false,
  dots: true,
  autoplay: false,
  stagePadding: 10,
  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

export default function Blog() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { blogs, totalBlogs } = useSelector((state) => ({
    blogs: state.userMgmtSlice.blogs,
    totalBlogs: state.userMgmtSlice.totalBlogs,
  }));

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    dispatch(
      getBlog({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,

        status: 0,
      })
    );
  }, [page, limit, search]);

  return (
    <>
     <Helmet>
        <title>News & Blogs - Salon Rated</title>
        <meta name="description" content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Blog Main start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Inspiration & Industry Insights
                  </h2>
                  <p>From our blog</p>
                </div>
              </Col>
            </Row>
            <div className="blog-recent-banner">
              <Row className="h-100">
                <Col lg={6}>
                  <div className="blog-slide-text">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      items={1}
                      nav={true}
                      {...optionsNewslider}
                    >
                      {blogs?.map((item) => {
                        return (
                          <>
                            <div class="item clients-say-comment">
                              <div className="clients-say-right blog-top-slide">
                                <p>
                                  {moment(item?.createdAt).format("Do MMMM")}
                                </p>
                                <h2 className="RecifeDisplay common-heading">
                                  {item?.title}
                                </h2>
                                <div className="red-more-btn">
                                  <Link to={`/blogdetail/${item?.blog_slug}`}>
                                    Read More
                                    <span>
                                      <img
                                        className="blog-next"
                                        src={
                                          require("../assets/images/blog-next.svg")
                                            .default
                                        }
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text mb-5">
                  <h2 className="RecifeDisplay common-heading">
                    Popular Blogs
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="aur-blog-box">
                  <Row>
                    {blogs?.map((item) => {
                      return (
                        <>
                          <Col lg={6}>
                            <div className="blog-card-box">
                              <img
                                className="black-logo blog-card-img"
                                src={
                                  item?.image
                                    ? BASE_URL + item?.image
                                    : require("../assets/images/blog-img.png")
                                }
                              />
                              <div className="blog-text-card">
                                <p className="Futura-Lt-BT">
                                  {moment(item?.createdAt).format("Do MMMM") ||
                                    "N/A"}
                                </p>
                                <p className="blog-text">{item?.title}</p>
                                <Link to={`/blogdetail/${item?.blog_slug}`}>
                                  Read More
                                  <span>
                                    <svg
                                      width="17"
                                      height="12"
                                      viewBox="0 0 17 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 5.80635C0.187595 5.22654 0.580938 5.02376 1.1528 5.02693C7.48865 5.03643 7.17095 5.03327 13.5068 5.03327C13.5976 5.03327 13.6853 5.03327 13.8245 5.03327C13.7247 4.92237 13.6581 4.8495 13.5915 4.77663C12.5991 3.74058 11.6067 2.70452 10.6112 1.67164C10.348 1.39599 10.2602 1.06332 10.3631 0.695786C10.4599 0.334593 10.705 0.106471 11.056 0.024094C11.4009 -0.0551149 11.6914 0.0652827 11.9365 0.32192C12.8351 1.25975 13.7368 2.19759 14.6384 3.13542C15.3101 3.83563 15.9818 4.53267 16.6505 5.23604C17.1165 5.72397 17.1165 6.27526 16.6505 6.76319C15.0862 8.39489 13.5189 10.0266 11.9516 11.6583C11.534 12.0924 10.9743 12.1145 10.5961 11.7153C10.2118 11.3129 10.236 10.7205 10.6596 10.2769C11.6399 9.25035 12.6233 8.22697 13.6067 7.20676C13.6672 7.14339 13.7368 7.0927 13.8003 7.03567C13.7943 7.01349 13.7882 6.99131 13.7821 6.96913C13.6974 6.96913 13.6127 6.96913 13.528 6.96913C7.18306 6.96913 7.4947 6.96596 1.1528 6.97547C0.580938 6.97547 0.187595 6.77586 0 6.19605C0 6.06298 0 5.93308 0 5.80635Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}

                    <Pagination
                      class="next-btn-new"
                      totalStuff={totalBlogs}
                      limit={limit}
                      setPage={setPage}
                    />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Blog Main end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
