import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { resetPassword } from "../redux/actions/userAuth";
import Layout from "../Layout/Layout";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);

  const validate = (values) => {
    const errors = {};
    // if (!values.email) {
    //   errors.email = "Required*";
    // }
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords doesn't match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      //email: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      setValidateValue(true);
      const dataVal = {
        //email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: token,
      };

      console.log(dataVal, "dataVal");

      dispatch(resetPassword(dataVal)).then((data) => {
        console.log(data, "reset______________pass");
        if (data?.payload?.success) {
          toast.success(data?.payload?.message);
          navigate("/Login");
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });

  return (
    <Layout>
      <div className="bg-color">
        {/* Signup flow Start */}
        <section className="Login-main-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-auto">
                <div className="login-main-box">
                  <h2 className="RecifeDisplay common-heading">
                    Forgot Password
                  </h2>

                  <form onSubmit={formik.handleSubmit}>
                    {/* <div className="logo-circle">
                            <img
                              src={require("../assets/img/logo-circle.png")}
                              alt="logo"
                            />
                          </div> */}

                    {/* <div className="login-input mt-4">
                      <label>Enter Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email *"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && (
                        <span className="formik-errors">
                          {formik.errors.email}
                        </span>
                      )}
                    </div> */}
                    <div className="login-input mt-4">
                      <label>Enter Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password *"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && (
                        <span className="formik-errors">
                          {formik.errors.password}
                        </span>
                      )}
                    </div>
                    <div className="login-input mt-4">
                      <label>Re Enter Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Re Enter Password *"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.confirmPassword && (
                        <span className="formik-errors">
                          {formik.errors.confirmPassword}
                        </span>
                      )}
                    </div>

                    <div className="login-btn">
                      <button type="submit" className="lg-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Signup flow End */}
      </div>
    </Layout>
  );
};
export default ResetPassword;
