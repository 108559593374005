import React from "react";
import {
  Container,
  Row,
  Col,
  NavLink,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <section className="footer-section">
      <Container>
        <Row>
          <Col lg={8}>
            <div className="left-side-text">
              <Row>
                <Col lg={4} md={4} sm={4}>
                  <div className="certified-right">
                    <img src={require("../assets/images/certify-img.webp")} />
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <div className="first-footer-box">
                    <ul>
                      <li>
                        <Link to="/whysalonrated" className="Futura-Lt-BT">
                          Why SalonRated
                        </Link>
                      </li>
                      <li>
                        <Link to="/complaintsmember" className="Futura-Lt-BT">
                          Report a member
                        </Link>
                      </li>
                      <li>
                        <Link to="/termsuse" className="Futura-Lt-BT">
                          Terms Of Use
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us" className="Futura-Lt-BT">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/helpsupport" className="Futura-Lt-BT">
                          Help & Support
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <div className="seco-footer-box">
                    <ul>
                      <li>
                        <Link to="/our-standard" className="Futura-Lt-BT">
                          Our Standard
                        </Link>
                      </li>
                      <li>
                        <Link to="/careers" className="Futura-Lt-BT">
                          Careers
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacynotice" className="Futura-Lt-BT">
                          Privacy Notice
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer-logo">
              <img src={require("../assets/images/black-logo.webp")} />
              <p className="m-0">Company Reg No: 14211935 </p>
              <p>© 2024 salonrated.com All rights reserved.</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="right-side-mail-send">
              <div className="association-sec mb-4">
                <p>In Association with</p>
                <img
                  className="img-fluid"
                  src={require("../assets/images/navy-blue-logo.png")}
                />
              </div>
              <h2 className="RecifeDisplay">Sign up to Our Newsletter</h2>
              <div className="mail-send-footer">
                <InputGroup className="mb-3 mt-3">
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    <button>
                      <img
                        src={require("../assets/images/mailsend.svg").default}
                      />
                    </button>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="footer-social">
                <Link to="https://www.facebook.com/SalonRated/" target="_blank">
                  <img
                    src={
                      require("../assets/images/facebook-f-icon.svg").default
                    }
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/officialsalonrated/"
                  target="_blank"
                >
                  <img
                    src={require("../assets/images/insta-f-icon.svg").default}
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/salon-rated"
                  target="_blank"
                >
                  <img
                    src={require("../assets/images/linkdin-f-icon.svg").default}
                  />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
