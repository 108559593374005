import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { ProgressBar } from "react-bootstrap";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/BcruptEncyptid";
import { userEditProfile } from "../redux/actions/userAuth";
import { brands, treatements } from "../utils/jsonData";
import Loader from "../Components/Loader";
import { getSalonProfileById } from "../redux/actions/userActions";
import { Helmet } from "react-helmet";

export default function SignUpThree() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const { id } = useParams();
  var dcryptId = Decryptedid(atob(id));
  const [prevImg, setPrevImg] = useState();
  const { salonDetail } = useSelector((state) => ({
    salonDetail: state.userMgmtSlice.salonDetail,
  }));

  useEffect(() => {
    dispatch(getSalonProfileById({ id: dcryptId, admin: "noclicks" }));
  }, [dcryptId]);
  console.log(salonDetail, "salonDetail");
  return (
    <>
          <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Become a SalonRated Salon
                  </h2>
                  <p>
                    Becoming SalonRated ensures that every potential customer
                    can trust that you're fully insured, certified, and
                    committed to providing exceptional professional hair and
                    beauty services. This instills complete confidence in your
                    business.
                  </p>
                </div>
              </Col>
            </Row>
            <Formik
              enableReinitialize={true}
              initialValues={{
                profile_logo: salonDetail?.profile_logo || "",
                treatments: salonDetail?.treatments || [],
                brand: salonDetail?.brand || [],
                business_desc: salonDetail?.business_desc || "",
                other_brand: salonDetail?.other_brand || "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.profile_logo) {
                  errors.profile_logo = "Required*";
                }
                if (!values.business_desc) {
                  errors.business_desc = "Required*";
                }
                if (!values.treatments || values.treatments.length === 0) {
                  errors.treatments = "At least one treatment must be selected";
                }

                if (!values.brand || values.brand.length === 0) {
                  errors.brand = "At least one brand must be selected";
                }

                if (!values.brand || values.brand.length === 0) {
                  errors.brand = "At least one brand must be selected";
                }

                if (
                  values.business_desc.split(/\s+/).length < 10 ||
                  values.business_desc.split(/\s+/).length > 200
                ) {
                  errors.business_desc = "Words must be between 10 and 200.";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setProgress(true);
                console.log(values, "values");
                values.id = dcryptId;
                values.step_status = 3;
                const formData = new FormData();
                for (let key in values) {
                  if (key == "treatments" || key == "brand") {
                    for (const element of values[key]) {
                      formData.append(key, element);
                    }
                  } else {
                    formData.append(key, values[key]);
                  }
                }

                dispatch(userEditProfile(formData)).then((data) => {
                  if (data?.payload?.success) {
                    resetForm();
                    setTimeout(() => {
                      setProgress(false);
                    }, 1000);
                    navigate(`/signupfour/${id}`);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <Row>
                    <Col lg={12} className="mx-auto">
                      <div className="sign-up-details-box">
                        <div className="mt-0">
                          <div className="pagination-tab">
                            <div className="tab-dots">
                              <ul>
                                <li className="active-dot">
                                  <p>Step 1</p>
                                </li>
                                <li className="active-dot">
                                  {" "}
                                  <p>Step 2</p>
                                </li>
                                <li className="active-dot">
                                  {" "}
                                  <p>Step 3</p>
                                </li>
                                <li className="active-dot">
                                  {" "}
                                  <p>Step 4</p>
                                </li>
                                <li>
                                  {" "}
                                  <p>Step 5</p>
                                </li>
                                <li>
                                  {" "}
                                  <p>Step 6</p>
                                </li>
                              </ul>
                            </div>
                            <ProgressBar now={60} />
                          </div>
                          <h2 className="text-center choose-plan-title">
                            PROFILE DETAILS
                          </h2>
                          <Row className="mt-5">
                            <Col lg={12}>
                              <div className="sign-profile">
                                {/* <label>Business Name</label> */}
                                <img
                                  src={require("../assets/images/pro-img.png")}
                                />
                                <div className="sign-upload-btn">
                                  <button>Upload Your Logo</button>
                                  <input
                                    name="profile_logo"
                                    type="file"
                                    onChange={(e) => {
                                      setPrevImg(
                                        URL.createObjectURL(e.target.files[0])
                                      );
                                      setFieldValue(
                                        "profile_logo",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                </div>
                                {prevImg ? (
                                  <img className="view-img" src={prevImg} />
                                ) : (
                                  values.profile_logo && (
                                    <img
                                      className="view-img"
                                      src={
                                        process.env.REACT_APP_HOST_NAME +
                                        values.profile_logo
                                      }
                                    />
                                  )
                                )}
                                <span className="formik-errors">
                                  {errors.profile_logo &&
                                    touched.profile_logo &&
                                    errors.profile_logo}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <Col lg={12} className="mb-4">
                              {" "}
                              <label>Treatements You Offer:</label>
                            </Col>
                            <div className="main-category">
                              {treatements?.map((item) => (
                                <div
                                  className="category-select"
                                  key={item.label}
                                >
                                  <Field
                                    class="form-check-input"
                                    type="checkbox"
                                    name="treatments"
                                    id={item.label}
                                    value={item.label}
                                    checked={values.treatments.includes(
                                      item.label
                                    )}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor={item.value}>
                                    {item.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <span className="formik-errors">
                              {errors.treatments &&
                                touched.treatments &&
                                errors.treatments}
                            </span>
                          </Row>
                        </div>

                        <div className="mt-5">
                          <Row>
                            <Col lg={12}>
                              <div className="profile-descri">
                                <label>Describe Your Business</label>
                                <textarea
                                  class="form-control"
                                  id=""
                                  rows="6"
                                  placeholder="Type Here..."
                                  name="business_desc"
                                  onChange={handleChange}
                                  value={values.business_desc}
                                ></textarea>
                                <p>200 words max</p>
                              </div>
                              <span className="formik-errors">
                                {errors.business_desc &&
                                  touched.business_desc &&
                                  errors.business_desc}
                              </span>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <Col lg={12} className="mb-4">
                              {" "}
                              <label>Brands:</label>
                            </Col>
                            {/* <Col lg={4}>
                              <div className="category-select">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id=""
                                />
                                <label>Bio Sculpture</label>
                              </div>
                            </Col> */}
                            <Col lg={12}>
                              <div className="main-category">
                                {brands?.map((item) => (
                                  <div
                                    className="category-select"
                                    key={item.label}
                                  >
                                    <Field
                                      class="form-check-input"
                                      type="checkbox"
                                      name="brand"
                                      id={item.label}
                                      value={item.label}
                                      checked={values.brand.includes(
                                        item.label
                                      )}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor={item.label}>
                                      {item.label}
                                    </label>

                                    <div className="new-filed-set">
                                      {item.label == "Other" &&
                                        values.brand.includes(item.label) && (
                                          <>
                                            <div>
                                              <p>Please Specify</p>
                                              <textarea
                                                class="form-control"
                                                rows="3"
                                                placeholder="Type Here..."
                                                name="other_brand"
                                                onChange={handleChange}
                                                value={values.other_brand}
                                              />
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <span className="formik-errors">
                                {errors.brand && touched.brand && errors.brand}
                              </span>
                            </Col>
                            <Col lg={6}></Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <div className="Salon-Signup-btn">
                                <Link
                                  to={`/signup-time/${id}`}
                                  className="me-3 back-sign"
                                >
                                  Back
                                </Link>
                                <button type="submit">Continue</button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Container>
        </section>

        {/* Sign Up Main Text end */}

        {/* footer section start  */}
        {progress && <Loader />}
        {/* footer section end  */}
      </Layout>
    </>
  );
}
