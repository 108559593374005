import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import { Rating } from "react-simple-star-rating";
import Layout from "../Layout/Layout";
import { Field, Formik } from "formik";
import { createReview } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/BcruptEncyptid";
import toast from "react-hot-toast";

export default function LeaveReview() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  var dcryptId = Decryptedid(atob(params.id));
  const [rating, setRating] = useState(0);
  const { statics } = useSelector((state) => ({
    statics: state.userMgmtSlice.statics,
  }));

  const handleRating = (rate,setFieldValue) => {
    setFieldValue('rating',rate);
    setRating(rate);
  };

  console.log(dcryptId,"dcryptId");
  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Help & Support start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Leave us a Review
                  </h2>
                </div>
              </Col>
            </Row>
            <Formik
              initialValues={{
                title: "",
                name: "",
                address: "",
                treatments: [],
                rating: "",
                date_experience: "",
                suggest_a_frnd: "",
                value_for_money: "",
                description: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.title) {
                  errors.title = "Required*";
                }
                if (!values.name) {
                  errors.name = "Required*";
                }
                if (!values.treatments || values.treatments.length === 0) {
                  errors.treatments = "At least one treatment must be selected";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(values, "values");
                values.salonId = dcryptId;
                

                dispatch(createReview(values)).then((data) => {
                  if (data?.payload?.success) {
                    console.log(data?.payload?.success,"revirewew");
                    resetForm();
                    toast.success("Thank you for the review. It has been successfully added.")
                    navigate(
                      `/salon/${dcryptId}`
                    );
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <Row>
                    <Col lg={10} className="mx-auto">
                      <div className="sign-up-details-box">
                        <div className="">
                          <div className="drop-us-line">
                            <h2>Please fill the below details</h2>
                          </div>
                          <Row>
                            <Col lg={12}>
                              <div className="sign-detail-input">
                                <label>Review Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Write a headline for your review"
                                  name="title"
                                  onChange={handleChange}
                                  value={values.title}
                                />
                                  <span className="formik-errors">
                                    {errors.title &&
                                      touched.title &&
                                      errors.title}
                                  </span>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="sign-detail-input">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Name"
                                  name="name"
                                  onChange={handleChange}
                                  value={values.name}
                                />
                              </div>
                              <span className="formik-errors">
                                    {errors.name &&
                                      touched.name &&
                                      errors.name}
                                  </span>
                            </Col>
                            <Col lg={12}>
                              <div className="sign-detail-input">
                                <label>Where are you from ?</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Where are you from ?"
                                  name="address"
                                  onChange={handleChange}
                                  value={values.address}
                                />
                                   <span className="formik-errors">
                                    {errors.address &&
                                      touched.address &&
                                      errors.address}
                                  </span>
                              </div>
                            </Col>
                          </Row>
                          <div className="sign-bottom-border"></div>
                          <Row className="mt-5">
                            <h2>Treatment Received</h2>
                              {statics?.treatements?.map((item) => (
                                <Col lg={4}>
                                  <div
                                    className="category-select"
                                    key={item.name}
                                  >
                                    <Field
                                      class="form-check-input"
                                      type="checkbox"
                                      name="treatments"
                                      id={item.name}
                                      value={item.name}
                                      checked={values.treatments.includes(
                                        item.name
                                      )}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor={item.value}>
                                      {item.name}
                                    </label>
                                  </div>
                                </Col>
                              ))}
                              <span className="formik-errors">
                                {errors.treatments &&
                                  touched.treatments &&
                                  errors.treatments}
                              </span>
                            </Row>
                          <div className="sign-bottom-border"></div>

                          <Row className="mt-5">
                            <Col lg={12}>
                              <div className="Rate-Us">
                                <h2>Rate your experience</h2>
                                <Rating
                                /* Available Props */
                                ratingValue={rating} size={30} onClick={(val)=>handleRating(val,setFieldValue)} 
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="sign-bottom-border"></div>
                          <Row className="mt-5">
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Date of Experience</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Date of experience"
                                  name="date_experience"
                                  onChange={handleChange}
                                  value={values.date_experience}
                                />
                                <span className="formik-errors">
                                    {errors.date_experience &&
                                      touched.date_experience &&
                                      errors.date_experience}
                                  </span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Would you recommend us to a friend?</label>
                                <div className="radio-buttons">
                                  <div className="button-inner">
                                    <input 
                                    type="radio" 
                                    name="suggest_a_frnd" 
                                    value="1"
                                    onChange={handleChange}
                                    checked={values.suggest_a_frnd == "1"}

                                    />
                                    <span>Yes</span>
                                  </div>

                                  <div className="button-inner">
                                    <input 
                                    type="radio" 
                                    name="suggest_a_frnd" 
                                    value="0"
                                        onChange={handleChange}
                                        checked={values.suggest_a_frnd == "0"}
                                    />
                                    <span>No</span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="sign-detail-input">
                                <label>Value for money?</label>
                                <div className="radio-buttons">
                                  <div className="button-inner">
                                    <input
                                      type="radio"
                                      name="value_for_money" 
                                      value="1"
                                      onChange={handleChange}
                                      checked={values.value_for_money == "1"}
                                    />
                                    <span>Yes</span>
                                  </div>

                                  <div className="button-inner">
                                    <input
                                      type="radio"
                                      name="value_for_money" 
                                      value="0"
                                      onChange={handleChange}
                                      checked={values.value_for_money == "0"}
                                    />
                                    <span>No</span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="sign-bottom-border"></div>
                          <div className="mt-5">
                            <h2>Tell us about your experience</h2>
                            <Row>
                              <Col lg={12}>
                                <div className="sign-detail-input">
                                  <textarea
                                    class="form-control"
                                    id=""
                                    rows="6"
                                    placeholder="Comment"
                                    name="description"
                                    onChange={handleChange}
                                    value={values.description}
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <Row>
                            <Col lg={12}>
                              <div className="drop-us-submit">
                                <button type="submit">Submit</button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>

          </Container>
        </section>

        {/*  Help & Support end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
