import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { userRegister } from "../redux/actions/userAuth";
import { Decryptedid, Encryptedid } from "../utils/BcruptEncyptid";
import useTimePicker from "../Hooks/timePicker";
import { openingHoursData } from "../utils/rawData";
import {
  createOpeningHours,
  fetchOpeningHours,
} from "../redux/actions/adminActions";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";
import { Helmet } from "react-helmet";
export default function SignUpTime() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  var dcryptId = Decryptedid(atob(id));
  const { timeRanges } = useTimePicker();
  const [progress, setProgress] = useState(false);
  const [openingHour, setOpeningHours] = useState();

  useEffect(() => {
    if (dcryptId) {
      dispatch(fetchOpeningHours(dcryptId)).then((item) => {
        console.log(item, "item------");
        if (item?.payload?.success) {
          setOpeningHours(item?.payload?.data);
        }
        //
      });
    }
  }, [dcryptId]);

  return (
    <>
          <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Become a SalonRated Salon
                  </h2>
                  <p>
                    Becoming SalonRated ensures that every potential customer
                    can trust that you're fully insured, certified, and
                    committed to providing exceptional professional hair and
                    beauty services. This instills complete confidence in your
                    business.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mx-auto">
                <Formik
                  // enableReinitialize: true
                  innerRef={formRef}
                  initialValues={{
                    availabeDays: openingHour?.availabeDays || [
                      "Mon",
                      "Tue",
                      "Wed",
                      "Thur",
                      "Fri",
                    ],
                    opening_hours:
                      openingHour?.opening_hours || openingHoursData,
                  }}
                  onSubmit={(values) => {
                    setProgress(true);
                    console.log(values, "formik");
                    values.id = dcryptId;
                    values.step_status = 10;
                    dispatch(createOpeningHours(values)).then((data) => {
                      if (data?.payload?.success) {
                        toast.success(data?.payload?.message);

                        setTimeout(() => {
                          setProgress(false);
                          navigate(`/signupthree/${id}`);
                        }, 1000);
                      } else {
                        setProgress(false);
                      }
                    });
                  }}
                  render={({ values, handleChange, setFieldValue }) => (
                    <Form>
                      <FieldArray
                        name="opening_hours"
                        render={(arrayHelpers) => (
                          <>
                            <div className="sign-up-details-box">
                              <div className="pagination-tab">
                                <div className="tab-dots">
                                  <ul>
                                    <li className="active-dot">
                                      <p>Step 1</p>
                                    </li>
                                    <li className="active-dot">
                                      {" "}
                                      <p>Step 2</p>
                                    </li>
                                    <li className="active-dot">
                                      {" "}
                                      <p>Step 3</p>
                                    </li>
                                    <li>
                                      {" "}
                                      <p>Step 4</p>
                                    </li>
                                    <li>
                                      {" "}
                                      <p>Step 5</p>
                                    </li>
                                    <li>
                                      {" "}
                                      <p>Step 6</p>
                                    </li>
                                  </ul>
                                </div>
                                <ProgressBar now={40} />
                              </div>
                              <h2 className="text-center choose-plan-title">
                                OPENING DAYS
                              </h2>
                              {/* <Row className="mt-3">
                                <Col lg={12}>
                                  <Row>
                                    <Col lg={12}>
                                      <div className="opening-tab d-flex justify-content-between align-items-center">
                                        <h2 className="mb-0">
                                          Available 24/7?
                                        </h2>
                                        <div className="opening-tabs-right d-flex gap-3">
                                          {" "}
                                          <div className="category-select mt-0 mb-0">
                                            <Field
                                              className="form-check-input"
                                              type="radio"
                                              name="availabe247"
                                              id="opening-yes"
                                              value="Yes" // Assign value to "Yes" option
                                              onChange={handleChange} // Handle change event
                                            />
                                            <label htmlFor="opening-yes">
                                              Yes
                                            </label>
                                          </div>
                                          <div className="category-select mt-0 mb-0">
                                            <Field
                                              className="form-check-input"
                                              type="radio"
                                              name="availabe247"
                                              id="opening-no"
                                              value="No" // Assign value to "No" option
                                              onChange={handleChange} // Handle change event
                                            />
                                            <label htmlFor="opening-no">
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row> */}
                              <Row className="mt-2">
                                <Col lg={12}>
                                  <div className="profile-descri">
                                    <Row className="mt-4">
                                      {openingHoursData?.map((item) => {
                                        return (
                                          <Col>
                                            <div className="category-select">
                                              <Field
                                                class="form-check-input"
                                                type="checkbox"
                                                name="availabeDays"
                                                value={item.shortDay}
                                                checked={values?.availabeDays?.includes(
                                                  item?.shortDay
                                                )}
                                                onChange={handleChange}
                                              />
                                              <label>{item?.shortDay}</label>
                                            </div>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <Row className={"mt-2"}>
                                <Col lg={12}>
                                  <div className="profile-descri">
                                    <Row className="mt-4">
                                      <Col lg={12}>
                                        <div className="opening-days-select mt-4">
                                          {values?.opening_hours?.map(
                                            (item, itemIndex) => {
                                              return (
                                                <>
                                                  <FieldArray
                                                    name={`opening_hours.${itemIndex}.arr`}
                                                  >
                                                    {(subArrayHelpers) => (
                                                      <div
                                                        index={itemIndex}
                                                        className={
                                                          values?.availabeDays?.includes(
                                                            item?.shortDay
                                                          )
                                                            ? "col-lg-12"
                                                            : "col-lg-12 d-none"
                                                          // : "col-lg-12 d-none"
                                                        }
                                                      >
                                                        <div className="buisness_form_fields">
                                                          <p>{item?.day}</p>
                                                          {item?.arr?.map(
                                                            (data, i) => {
                                                              return (
                                                                <>
                                                                  {i != 0 && (
                                                                    <p></p>
                                                                  )}
                                                                  {data?.reason ? (
                                                                    <>
                                                                      <input
                                                                        type="text"
                                                                        placeholder="Unavailable"
                                                                        name={`opening_hours.${itemIndex}.arr.${i}.reason`}
                                                                        value={
                                                                          values
                                                                            .opening_hours[
                                                                            itemIndex
                                                                          ].arr[
                                                                            i
                                                                          ]
                                                                            .reason
                                                                        }
                                                                        onChange={
                                                                          handleChange
                                                                        }
                                                                      />
                                                                      <div className="edit_btns">
                                                                        {i !=
                                                                          0 && (
                                                                          <button type="button">
                                                                            <img
                                                                              onClick={() =>
                                                                                subArrayHelpers.remove(
                                                                                  i
                                                                                )
                                                                              }
                                                                              src={
                                                                                require("../assets/images/remove_icon.svg")
                                                                                  .default
                                                                              }
                                                                              alt=""
                                                                            />
                                                                          </button>
                                                                        )}

                                                                        <img
                                                                          onClick={() => {
                                                                            subArrayHelpers.remove(
                                                                              0
                                                                            );
                                                                            subArrayHelpers.push(
                                                                              {
                                                                                start:
                                                                                  "08:00 am",
                                                                                end: "09:00 pm",
                                                                              }
                                                                            );
                                                                          }}
                                                                          src={
                                                                            require("../assets/images/addplus.svg")
                                                                              .default
                                                                          }
                                                                          alt=""
                                                                        />
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <select
                                                                        key={i}
                                                                        class="form-select add_width"
                                                                        aria-label="Default select example"
                                                                        name={`opening_hours.${itemIndex}.arr.${i}.start`}
                                                                        value={
                                                                          values
                                                                            .opening_hours[
                                                                            itemIndex
                                                                          ].arr[
                                                                            i
                                                                          ]
                                                                            .start
                                                                        }
                                                                        onChange={
                                                                          handleChange
                                                                        }
                                                                      >
                                                                        {timeRanges?.map(
                                                                          (
                                                                            time
                                                                          ) => (
                                                                            <option
                                                                              value={
                                                                                time
                                                                              }
                                                                            >
                                                                              {
                                                                                time
                                                                              }
                                                                            </option>
                                                                          )
                                                                        )}
                                                                      </select>
                                                                      <select
                                                                        class="form-select add_width"
                                                                        aria-label="Default select example"
                                                                        name={`opening_hours.${itemIndex}.arr.${i}.end`}
                                                                        value={
                                                                          values
                                                                            .opening_hours[
                                                                            itemIndex
                                                                          ].arr[
                                                                            i
                                                                          ].end
                                                                        }
                                                                        onChange={
                                                                          handleChange
                                                                        }
                                                                      >
                                                                        {timeRanges?.map(
                                                                          (
                                                                            time,
                                                                            ind
                                                                          ) => (
                                                                            <option
                                                                              value={
                                                                                time
                                                                              }
                                                                              disabled={
                                                                                timeRanges?.indexOf(
                                                                                  values
                                                                                    .opening_hours[
                                                                                    itemIndex
                                                                                  ]
                                                                                    .arr[
                                                                                    i
                                                                                  ]
                                                                                    .start
                                                                                ) >=
                                                                                ind
                                                                                  ? true
                                                                                  : false
                                                                              }
                                                                            >
                                                                              {
                                                                                time
                                                                              }
                                                                            </option>
                                                                          )
                                                                        )}
                                                                      </select>
                                                                      <div className="edit_btns">
                                                                        {
                                                                          <button type="button">
                                                                            <img
                                                                              onClick={() => {
                                                                                subArrayHelpers.remove(
                                                                                  i
                                                                                );
                                                                                if (
                                                                                  item
                                                                                    ?.arr
                                                                                    ?.length <
                                                                                  2
                                                                                ) {
                                                                                  subArrayHelpers.push(
                                                                                    {
                                                                                      reason:
                                                                                        "Unavailable",
                                                                                    }
                                                                                  );
                                                                                }
                                                                              }}
                                                                              src={
                                                                                require("../assets/images/remove_icon.svg")
                                                                                  .default
                                                                              }
                                                                              alt=""
                                                                            />
                                                                          </button>
                                                                        }

                                                                        <button type="button">
                                                                          <img
                                                                            onClick={() =>
                                                                              subArrayHelpers.push(
                                                                                {
                                                                                  start:
                                                                                    "08:00 am",
                                                                                  end: "09:00 pm",
                                                                                }
                                                                              )
                                                                            }
                                                                            src={
                                                                              require("../assets/images/addplus.svg")
                                                                                .default
                                                                            }
                                                                            alt=""
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </FieldArray>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="Salon-Signup-btn">
                                    <Link
                                      to={`/signuptwo/${id}`}
                                      className="me-3 back-sign"
                                    >
                                      Back
                                    </Link>
                                    <button type="submit">Continue</button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      />
                    </Form>
                  )}
                  enableReinitialize={true}
                />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sign Up Main Text end */}
        {progress && <Loader />}
        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
