import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import {Helmet} from "react-helmet";
export default function ComplaintsMember() {
  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 10);
  //   });
  // }, []);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
     <Helmet>
        <title>Complaints About A Member - Salon Rated</title>
        <meta name="description" content="If you are unhappy with the treatment or service you have received from Salon Rated Member you should follow the following steps." />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Privacy Notice start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Complaints About A Member
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="Terms-Use-box">
                  <p>
                    If you are unhappy with the treatment or service provided by
                    a SalonRated Member, please follow these steps:
                  </p>
                  <h4>1. Understand your legal rights</h4>
                  <p>The Consumer Rights Act 2015 (England and Wales) says:</p>
                  <p>
                    <i>
                      If a service was not provided with reasonable care and
                      skill or as agreed, the service provider must put things
                      right within a reasonable time and at their own cost.
                    </i>
                  </p>

                  <h4>2. Raise the issue with the salon</h4>

                  <p>
                    If you are not 100% happy with the service you have received
                    the best option is to calmly raise the issue whilst you are
                    still on the premises, or to make contact as soon as
                    possible after your appointment.
                  </p>
                  <p>
                    We would advise that you do not to go somewhere else to have
                    things put right without discussing with the original salon
                    first. The salon will need to see how things were left at
                    the end of your appointment and try and find a reasonable
                    resolution.
                  </p>

                  <p>
                    We would recommend that you ask to speak to the person who
                    deals with complaints privately and explain calmly why you
                    are not 100% happy.
                  </p>
                  <p>
                    A reputable salon will be keen to put things right. They
                    may:
                  </p>
                  <p>
                    <b>1 – Offer to rectify all or part of the service - </b>
                    this is the easiest and most effective way to resolve
                  </p>
                  <p>
                    <b>2 – Offer a full or partial refund - </b>this is unlikely
                    as the professional is unable to resell her time or reuse
                    any products
                  </p>
                  <p>
                    <b>
                      3 – Pay for you to have things put right somewhere else –
                    </b>
                    but the cost of this must be reasonable and must be
                    pre-agreed
                  </p>
                  <h4>3. Unresolved</h4>
                  <p>
                    You may find that you cannot find a mutual agreement, this
                    is not uncommon. If you cannot agree on how to solve the
                    complaint, your next step will be alternative dispute
                    resolution (ADR).
                  </p>
                  <p>
                    If you wish to discuss your issue with the SalonRated team
                    you can do this by contacting our dedicated team who will
                    remain impartial and offer advice to both the client and the
                    salon. Please contact the team via –
                    <Link to="report@salonrated.com">
                      report@salonrated.com
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Privacy Notice end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
