import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { getReviewById } from "../redux/actions/adminActions";
import moment from "moment";
export default function ReviewDetails() {
  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 10);
  //   });
  // }, []);

  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { reviewDetails } = useSelector((state) => ({
    reviewDetails: state.userMgmtSlice.reviewDetails
  }));


  useEffect(() => {
    dispatch(getReviewById(params.id));
  }, [])

  console.log(reviewDetails,"reviewDetails");
  

  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Careers start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Review Details
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={10} className="mx-auto">
                <div className="Review-Details-box">
                  <div className="review-rating">
                    <h2 className="FuturaBT">{reviewDetails?.name}</h2>
                    <p className="Futura-Lt-BT">{reviewDetails?.address}</p>

                    <div className="rating-star mb-4 mt-4">
                      <Rating initialValue={reviewDetails?.rating}
                      /* Available Props */
                      />
                    </div>
                  </div>
                  <div className="services-took">
                    <h2>What Services you took ?</h2>
                    <ul>
                      {
                        reviewDetails?.treatments?.map((item)=>{
                          return (
                            <>
                            <li>
                        <div className="services-box">
                          <p>{item}</p>
                        </div>
                      </li>
                            </>
                          )
                        })
                      }
                      
                     
                    </ul>
                  </div>
                  <div className="sign-bottom-border"></div>
                  <Row>
                    <Col lg={6}>
                      {/* <div className="review-data">
                        <h2>Date of Experience</h2>
                        <p className="Futura-Lt-BT">01-01-2024</p>
                      </div> */}
                      <div className="services-took mt-5">
                        <h2>Date of Experience</h2>
                        <p className="Futura-Lt-BT">{moment(reviewDetails?.date_experience).format("DD-MM-YYYY") ||
                        "N/A"}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="services-took mt-5">
                        <h2>Would you recommend us to a friend?</h2>
                        <p className="Futura-Lt-BT">{reviewDetails?.suggest_a_frnd ? 'Yes' : 'No'}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="services-took mt-2">
                        <h2>Value for Money?</h2>
                        <p className="Futura-Lt-BT">{reviewDetails?.value_for_money ? 'Yes' : 'No'}</p>
                      </div>
                    </Col>
                  </Row>

                  <div className="sign-bottom-border"></div>

                  <div className="services-took mt-5">
                    <h2>Description</h2>
                    <p className="Futura-Lt-BT">
                     {reviewDetails?.description}
                    </p>
                  </div>
                  <div className="sign-bottom-border"></div>
                  {/* <div className="services-took mt-5">
                    <h2>Images</h2>
                    <Row>
                      <Col lg={6}>
                        <div className="before-after-img">
                          <img
                            src={require("../assets/images/before-img.webp")}
                          />
                          <p>Before</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="before-after-img">
                          <img
                            src={require("../assets/images/after-img.webp")}
                          />
                          <p>After</p>
                        </div>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Careers end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
