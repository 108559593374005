import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import { useMemo } from "react";
import Layout from "../Layout/Layout";
import { treatements } from "../utils/jsonData";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getAllNearBySalons } from "../redux/actions/userActions";
import Maping from "../Components/Map/Maping";
import InitMap from "../Components/Map/InitMap";
import toast from "react-hot-toast";
import AutoCompleteSearch from "../Components/Map/AutoCompleteSearch";
import { Helmet } from "react-helmet";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SearchSalons() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const dispatch = useDispatch();
  let query = useQuery();
  let service_type = query.get("service");
  let lat = query.get("lat");
  let long = query.get("long");
  let company_name = query.get("company");

  //const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);
  const [serviceType, setServiceType] = useState();
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const [salonArrData, setSalonArrData] = useState();

  const { salonArr, subscription_data } = useSelector((state) => ({
    salonArr: state.userMgmtSlice.salonArr,
    subscription_data: state.userMgmtSlice.subscription_data,
  }));

  console.log(serviceType, "serviceType");

  useEffect(() => {
    setSalonArrData(salonArr);
  }, [salonArr]);

  useEffect(() => {
    let obj = {};
    if (!service_type && !company_name && !lat && !long) {
      dispatch(getAllNearBySalons({}));
    }
    if (service_type) {
      obj.service_type = service_type;
    }
    if (lat && long) {
      obj.lat = lat;
      obj.long = long;
    }
    if (company_name) {
      obj.company_name = company_name;
      dispatch(getAllNearBySalons(obj));
    }
    if (service_type || lat) {
      dispatch(getAllNearBySalons(obj));
    }
  }, [service_type, lat, long, company_name]);

  const searchService = () => {
    //if (serviceType)
    dispatch(
      getAllNearBySalons({
        service_type: serviceType,
        lat: googleLat,
        long: googleLong,
      })
    );
    //else toast.error("Please select service type before proceed.");
  };

  const appendHttps = (url) => {
    if (!url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
};

  return (
    <>
    <Helmet>
        <title>Salon Finder - Salon Rated</title>
        <meta name="description" content="Not Found" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Search Salons start */}

        <section className="Search-input-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="select-city justify-content-center">
                  <div className="services-types">
                    <label className="text-black">Service type</label>
                    <Form.Select
                      onChange={(e) => setServiceType(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">Service type (e.g hair)</option>
                      {treatements?.map((item) => (
                        <option value={item?.label}>{item?.label}</option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="select-city-input">
                    <label className="text-black">City</label>
                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                      /> */}
                    <InputGroup className="">
                      <AutoCompleteSearch
                        setGoogleLat={setGoogleLat}
                        setGoogleLong={setGoogleLong}
                      />
                      {/* <Autocomplete
                        style={{ width: "500px" }}
                        apiKey={"AIzaSyAMvWJAL65xBFjKtxOx5mrukmlND0quHIM"}
                        onPlaceSelected={(place) => {
                          console.log(place?.geometry?.location);
                          setGoogleLat(place?.geometry?.location?.lat());
                          setGoogleLong(place?.geometry?.location?.lng());
                        }}
                        types={["address"]}
                        options={{
                          types: ["(regions)"],
                          componentRestrictions: { country: "uk" },
                        }}
                      /> */}
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={require("../assets/images/loca-pic.svg").default}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div onClick={searchService} className="sreach-city">
                    <img
                      src={require("../assets/images/search-icon.svg").default}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Search Salonst end */}

        {/* Map section start  */}
        <section className="Map-section">
          <Container fluid>
            <Row>
              <Col lg={12} className="px-0">
                <div className="salon-map w-100">
                  <InitMap
                    salonArr={salonArrData}
                    lat={googleLat || lat}
                    long={googleLong || long}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Map section end  */}

        {/* Rated Salons section start  */}

        <section className="rated-salons">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="rated-salon-tittle">
                  <h2 className="RecifeDisplay common-heading">
                    SalonRated Salons
                  </h2>
                </div>
              </Col>
              <Col lg={11} className="mx-auto">
                <div
                  className={salonArrData?.length > 0 && "rated-salons-scroll"}
                >
                  {salonArrData?.map((item, i) => {
                    return (
                      <div
                       
                        className="rated-salons-card"
                      >
                        <span onClick={() => navigate(`/salon/${item?.salon_slug}`)} className="view-link-btn"></span>
                        <div className="salon-info">
                          <img
                            className="logo-show-salon"
                            src={
                              item?.profile_logo
                                ? BASE_URL + "/" + item?.profile_logo
                                : require("../assets/images/salon-logo-card.png")
                            }
                          />
                          <div className="salons-card-details">
                            <h3>{item?.business_name}</h3>
                            <p className="Futura-Lt-BT">{item?.address}</p>
                            {item?.subscription >= 2 && item?.telephone_number && (
                              <div className="salons-card-contact">
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/call-icon.svg")
                                        .default
                                    }
                                  />
                                </span>
                                <p>{item?.telephone_number}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {
                          <div className="salons-card-btn">
                            {item?.subscription >= 3 &&
                              item?.booking_url &&
                              item?.booking_url != "" && (
                                <a target="_blank" className="mb-2" href={item?.booking_url}>
                                  Book Now
                                </a>
                              )}
                            {item?.subscription == 3 &&
                              item?.website_url &&
                              item?.website_url != "" && (
                                <a href={appendHttps(item?.website_url)} target="_blank">
                                  Visit Website
                                </a>
                              )}
                          </div>
                        }
                      </div>
                    );
                  })}
                </div>
                {salonArrData?.length == 0 && (
                  <div className="no-data-main">
                    <p>No data available yet</p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>

        {/* Rated Salons section start  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
