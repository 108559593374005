import React from "react";

export default function Loader() {
   return (
    <>
     <div className="load-main">
        <div className="loader"></div>
    </div>
    </>
   )
}