import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import Layout from "../Layout/Layout";

export default function LiveChat() {
  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-login">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Livechat Page start */}

        <section className="Livechat-section">
          <Container>
            <Row>
              <Col lg={8} className="mx-auto">
                <div className="livechat-box">
                  <h2 className="RecifeDisplay common-heading">
                    Tawk Live Chat Integration
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Livechat Page end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
