import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import {Helmet} from "react-helmet";
export default function PrivacyNotice() {
  return (
    <>
     <Helmet>
        <title>Privacy Policy - Salon Rated</title>
        <meta name="description" content="This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.salonrated.com (the “Site”)." />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Privacy Notice start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading ">
                    Privacy Notice
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="Terms-Use-box">
                  <h4 className="mt-0">SALONRATED PRIVACY POLICY</h4>
                  <p>
                    This Privacy Policy describes how your personal information
                    is collected, used, and shared when you visit or make a
                    purchase from www.salonrated.com  (the “Site”).
                  </p>
                  <h4>PERSONAL INFORMATION WE COLLECT</h4>

                  <p>
                    When you visit the Site, we automatically collect certain
                    information about your device, including information about
                    your web browser, IP address, time zone, and some of the
                    cookies that are installed on your device. Additionally, as
                    you browse the Site, we collect information about the
                    individual web pages or products that you view, which
                    websites or search terms referred you to the Site, and
                    information about how you interact with the Site. We refer
                    to this automatically-collected information as “Device
                    Information.”
                  </p>
                  <p>
                    We collect Device Information using the following
                    technologies:
                  </p>

                  <ul>
                    <li>
                      “Cookies” are data files that are placed on your device or
                      computer and often include an anonymous unique identifier.
                      For more information about cookies, and how to disable
                      cookies, visit{" "}
                      <Link to="http://www.allaboutcookies.org">
                        http://www.allaboutcookies.org
                      </Link>
                      .
                    </li>
                    <li>
                      “Log files” track actions occurring on the Site, and
                      collect data including your IP address, browser type,
                      Internet service provider, referring/exit pages, and
                      date/time stamps.
                    </li>
                    <li>
                      “Web beacons,” “tags,” and “pixels” are electronic files
                      used to record information about how you browse the Site.
                    </li>
                  </ul>

                  <p>
                    Additionally, when you make a purchase or attempt to make a
                    purchase through the Site, we collect certain information
                    from you, including your name, billing address, shipping
                    address, payment information (including credit card
                    numbers), email address, and phone number.  We refer to this
                    information as “Order Information.”
                  </p>
                  <p>
                    When we talk about “Personal Information” in this Privacy
                    Policy, we are talking both about Device Information and
                    Order Information.
                  </p>

                  <h4>HOW DO WE USE YOUR PERSONAL INFORMATION?</h4>
                  <p>
                    We use the Order Information that we collect generally to
                    fulfil any orders placed through the Site (including
                    processing your payment information, arranging for shipping,
                    and providing you with invoices and/or order confirmations).
                    Additionally, we use this Order Information to:
                  </p>

                  <ul>
                    <li>Communicate with you</li>
                    <li>Screen our orders for potential risk or fraud</li>
                    <li>
                      When in line with the preferences you have shared with us,
                      provide you with information or advertising relating to
                      our products or services
                    </li>
                    <li>To identify you as a consumer or a professional </li>
                  </ul>

                  <p>
                    We use the Device Information that we collect to help us
                    screen for potential risk and fraud (in particular, your IP
                    address), and more generally to improve and optimise our
                    Site (for example, by generating analytics about how our
                    customers browse and interact with the Site, and to assess
                    the success of our marketing and advertising campaigns){" "}
                  </p>

                  <h4>SHARING YOUR PERSONAL INFORMATION</h4>

                  <p>
                    We share your Personal Information with third parties
                    including partnered companies under the Site umbrella to
                    help us use your Personal Information, as described above.
                    For example, we use Shopify to power our online store–you
                    can read more about how Shopify uses your Personal
                    Information here:
                    <Link to="https://www.shopify.com/legal/privacy">
                      https://www.shopify.com/legal/privacy
                    </Link>
                    . We also use Google Analytics to help us understand how our
                    customers use the Site–you can read more about how Google
                    uses your Personal Information here:
                    <Link to="https://www.google.com/intl/en/policies/privacy/">
                      https://www.google.com/intl/en/policies/privacy/
                    </Link>
                    . You can also opt-out of Google Analytics here:
                    <Link to="https://tools.google.com/dlpage/gaoptout">
                      https://tools.google.com/dlpage/gaoptout
                    </Link>
                    . We also share your data with our partner companies to
                    determine your position as a consumer or professional and to
                    market other product and services offered by our partner
                    companies.
                  </p>
                  <p>
                    We may at times share your salon information with industry
                    specific companies whom will offer marketing and advertising
                    of products and services to you. This includes insurance and
                    accreditation providers .
                  </p>
                  <p>
                    Finally, we may also share your Personal Information to
                    comply with applicable laws and regulations, to respond to a
                    subpoena, search warrant or other lawful request for
                    information we receive, or to otherwise protect our rights.
                  </p>

                  <h4>BEHAVIOURAL ADVERTISING</h4>

                  <p>
                    As described above, we use your Personal Information to
                    provide you with targeted advertisements or marketing
                    communications we believe may be of interest to you. For
                    more information about how targeted advertising works, you
                    can visit the Network Advertising Initiative’s (“NAI”)
                    educational page at
                    <Link to="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                      http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                    </Link>
                    .
                  </p>

                  <p>
                    Additionally, you can opt out of some of these services by
                    visiting the Digital Advertising Alliance’s opt-out portal
                    at:{" "}
                    <Link to="http://optout.aboutads.info/">
                      http://optout.aboutads.info/
                    </Link>
                  </p>

                  <h4>DO NOT TRACK</h4>
                  <p>
                    Please note that we do not alter our Site’s data collection
                    and use practices when we see a Do Not Track signal from
                    your browser.
                  </p>

                  <h4>YOUR RIGHTS</h4>
                  <p>
                    If you are a European resident, you have the right to access
                    personal information we hold about you and to ask that your
                    personal information be corrected, updated, or deleted. If
                    you would like to exercise this right, please contact us
                    through the contact information below.
                  </p>
                  <p>
                    Additionally, if you are a European resident please note
                    that we are processing your information in order to fulfil
                    contracts we might have with you (for example if you make an
                    order through the Site), or otherwise to pursue our
                    legitimate business interests listed above. Additionally,
                    please note that your information will be transferred
                    outside of Europe, including to Canada and the United
                    States.
                  </p>

                  <h4>DATA RETENTION</h4>

                  <p>
                    When you place an order through the Site, we will maintain
                    your Order Information for our records unless and until you
                    ask us to delete this information.
                  </p>

                  <h4>CHANGES</h4>

                  <p>
                    We may update this privacy policy from time to time in order
                    to reflect, for example, changes to our practices or for
                    other operational, legal or regulatory reasons.
                  </p>

                  <h4>CONTACT US</h4>

                  <p>
                    For more information about our privacy practices, if you
                    have any questions, or if you would like to make a
                    complaint, please contact us by e-mail at
                    hello@salonrated.com or by mail using the details provided
                    below:
                  </p>

                  <p>
                    SalonRated, Highfield Farm, Walkeringham Road, Walkeringham,
                    DN10 4NB
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Privacy Notice end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
