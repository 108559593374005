import React, { useEffect, useRef } from 'react';

function AutoCompleteSearch({ setGoogleLat, setGoogleLong }) {
    const inputRef = useRef(null);

    useEffect(() => {
        // Initialize the autocomplete search
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            componentRestrictions: { country: 'uk' },
            types: ['geocode']
        });

        autocomplete.addListener('place_changed', () => {
            console.log("place--------------change");
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.log("No details available for input: '" + place.name + "'");
                return;
            }

            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();
            setGoogleLat(latitude);
            setGoogleLong(longitude);
        });

    }, []);

    // Event listener to detect input changes
    const handleInputChange = (e) => {
        setGoogleLat();
        setGoogleLong();
    };

    return (
        <div>
            <input ref={inputRef} onChange={handleInputChange} type="text" placeholder="Enter a location" />
        </div>
    );
}

export default AutoCompleteSearch;
