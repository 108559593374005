import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import dashboardReducer from "./reducers/dashboardSlice";
import managePropertySlice from "./reducers/managePropertySlice";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    dashboardSlice: dashboardReducer,
    managePropertySlice: managePropertySlice,
  },
});
