import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";



const getAllFeaturedSalon = createAsyncThunk(
  "getAllFeaturedSalon",
  async (data, thunkApi) => {
    const response = await adminApi.get(`/getAllFeaturedSalon`);
    return response.data;
  }
);

const getProfile = createAsyncThunk(
  "getProfile",
  async (data, thunkApi) => {
    const response = await adminApi.get(`/getProfile`);
    return response.data;
  }
);

const getAllNearBySalons = createAsyncThunk(
  "getAllNearBySalons",
  async (data, thunkApi) => {
    const response = await adminApi.post(`/getAllNearBySalons`, data);
    return response.data;
  }
);

const getSalonProfileById = createAsyncThunk(
  "getSalonProfileById",
  async ({id,booking, admin}, thunkApi) => {
    console.log(admin,"admin");
    const response = await adminApi.get(`/getSalonProfileById?id=${id}&booking=${booking}&admin=${admin}`);
    return response.data;
  }
);


const uploadImage = createAsyncThunk("uploadImage", async (dataf) => {
  const { data } = await adminApi.post("/auth/uploadImage", dataf);
  return data;
});



export {
  uploadImage,
  getProfile,
  getAllNearBySalons,
  getSalonProfileById,
  getAllFeaturedSalon
};
