import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getAllFeaturedSalon, getAllNearBySalons, getProfile, getSalonProfileById } from "../actions/userActions";
import { fetchOpeningHours, getAllReviews, getBlog, getBlogById, getReviewById, getStatics, getSubscriptionById } from "../actions/adminActions";

const initialState = {
  userDetails: null,
  salonArr:[],
  salonDetail:null,
  statics:null,
  subscription_data:null,
  reviews:[],
  totalReviews:0,
  reviewDetails:null,
  blogs: [],
  totalBlogs: null,
  blogDetails: null,
  opening_hours:{},
  featuredArr:[]
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllNearBySalons.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.salonArr = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getSalonProfileById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.salonDetail = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getStatics.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statics = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getSubscriptionById.fulfilled, (state, { payload }) => {
        state.subscription_data = payload?.data;
      })
      .addCase(getAllReviews.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.reviews = payload.data.review;
          state.totalReviews = payload.data.totalReviews;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getReviewById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.reviewDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getBlog.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.blogs = payload.data.service;
          state.totalBlogs = payload.data.totalServices;
        }
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.blogDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(fetchOpeningHours.fulfilled, (state, { payload }) => {
        state.opening_hours = payload?.data;
      })
      .addCase(getAllFeaturedSalon.fulfilled, (state, { payload }) => {
        state.featuredArr = payload?.data;
      })
      
  },
});

export default userMgmtSlice.reducer;
