import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import { useSelector } from "react-redux";

export default function WeRecommended() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { statics } = useSelector((state) => ({
    statics: state.userMgmtSlice.statics,
  }));
  console.log(statics?.brand[0]?.brand_logo, "statics");
  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Why become a Salon Rated Salon? start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Our Most Loved Brands{" "}
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="we-recommend-box">
                  <h2 className="RecifeDisplay common-heading mb-5">
                    Beauty Brands
                  </h2>
                  <Row>
                    {statics?.brand?.map((item, i) => {
                      console.log( BASE_URL + item?.brand_logo,"item?.brand_logo");
                      return (
                        <>
                          <Col lg={3}>
                            <div onClick={() => window.open(item?.link, "_blank")} className="brand-logos-box">
                              <img
                                src={
                                  BASE_URL + item?.brand_logo
                                }
                              />
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                  <h2 className="RecifeDisplay common-heading mb-5 mt-5">
                    Insurance Providers & Industry Bodies
                  </h2>
                  <Row>
                    <Col lg={3}>
                      <div className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/habia.svg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/NHBf.svg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div onClick={() => window.open("https://www.salongold.co.uk/", "_blank")} className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/salongold.svg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                    {/* <Col lg={3}>
                      <div className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/patchport.svg")
                              .default
                          }
                        />
                      </div>
                    </Col> */}

                    <Col lg={3}>
                      <div onClick={() => window.open('https://www.beautybacked.com/', "_blank")} className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/beautybacked.svg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="brand-logos-box">
                        <img
                          src={
                            require("../assets/images/recommendedlogo/british-beauty-council.svg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Why become a Salon Rated Salon? end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
