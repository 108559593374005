import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import Layout from "../Layout/Layout";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { login } from "../redux/actions/userAuth";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";


export default function Login() {
  const dispatch = useDispatch();

  function onChange(value) {
    console.log("Captcha value:", value);
  }


  return (
    <>
       <Helmet>
        <title>Log In Salon Rated - Salon Rated</title>
        <meta name="description" content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK" />
    </Helmet>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-login">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Login Page start */}

        <section className="Login-main-section">
          <Container>
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required*";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                if (!values.password) {
                  errors.password = "Required*";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values, "dataattttttttt");
                dispatch(login(values));

                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={(e) => {
                  // setValidateValue(true);
                  handleSubmit(e);
                }}>
                  <Row>
                    <Col lg={7} className="mx-auto">
                      <div className="login-main-box">
                        <h2 className="RecifeDisplay common-heading">Login</h2>
                        <p>Please enter your login credentials</p>
                        <div className="login-input">
                          <label>Email</label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="formik-errors">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>
                        <div className="login-input">
                          <label>Password</label>
                          <input
                            className="form-control"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <span className="formik-errors">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </div>
                        <div className="forgot-page-link">
                          <Link to="/forgotpassword">Forgot Password</Link>
                        </div>
                        {/* <ReCAPTCHA
                          sitekey="6Le8_cMpAAAAADQulg7LRXFM3gFysVrHCBzDtMHd"
                          onChange={onChange}
                        /> */}
                        <div className="login-btn">
                          <button type="submit">Log In</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Container>
        </section>

        {/* Login Page end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
