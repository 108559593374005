import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "bootstrap";
import Layout from "../Layout/Layout";
import { Formik } from "formik";
import { forgotPassword } from "../redux/actions/userAuth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validateValue, setValidateValue] = useState(false);

  return (
    <>
      <Layout>
        {/* Header section start  */}

        <section className="banner-img-login">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Login Page start */}

        <section className="Login-main-section">
          <Container>
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              validateOnChange={validateValue}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setValidateValue(true);
                dispatch(forgotPassword(values)).then((data) => {
                  if (data?.payload?.success) {
                    resetForm();
                    toast.success(data?.payload?.message);
                    navigate("/Login");
                  } else {
                    toast.error(data?.payload?.message);
                  }
                });
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={7} className="mx-auto">
                      <div className="login-main-box">
                        <h2 className="RecifeDisplay common-heading">
                          Forgot Password
                        </h2>

                        <div className="login-input mt-4">
                          <label>Enter Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email *"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {errors.email && (
                            <span className="formik-errors">
                              {errors.email}
                            </span>
                          )}
                        </div>

                        <div className="login-btn">
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Container>
        </section>

        {/* Login Page end */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
