import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { userEditProfile } from '../redux/actions/userAuth';

const PaymentSuccess = () => {

    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
            dispatch(userEditProfile({
                id: params.id,
                step_status: 5,
                subscription: params.subscription
            })).then(
                (data) => {
                    if (data?.payload?.success) {
                        toast.success(data?.payload?.message);
                        setTimeout(() => {
                            window.location.href = "/Login";
                        }, 2000)

                    }
                }
            );
        }

    }, [params.id])

    return (
        <></>
    )
}

export default PaymentSuccess;