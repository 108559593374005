import React, { useEffect, useState } from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export default function Header() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const pages = ["/", "/contact-us", "/dashboard", "/SalonDetail"];

  return (
    <Container
      fluid
      className={
        scroll
          ? "simple-header active px-0"
          : pages.includes(window.location.pathname)
          ? "simple-header px-0"
          : "simple-header px-0 black-active"
      }
    >
      <Row className="m-0">
        <Col lg={12} className="px-0">
          <div className="navbar-top">
            <div className="social-icons-head">
              <Link to="https://www.facebook.com/SalonRated/" target="_blank">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4375 0C16.4188 0 17.2579 0.348416 17.9548 1.04525C18.6516 1.74208 19 2.58116 19 3.5625V15.4375C19 16.4188 18.6516 17.2579 17.9548 17.9548C17.2579 18.6516 16.4188 19 15.4375 19H13.112V11.64H15.5736L15.9447 8.77018H13.112V6.93945C13.112 6.47765 13.2089 6.13129 13.4027 5.90039C13.5965 5.66949 13.9737 5.55404 14.5345 5.55404L16.0436 5.54167V2.98112C15.5241 2.9069 14.7901 2.86979 13.8418 2.86979C12.7203 2.86979 11.8235 3.19965 11.1514 3.85938C10.4793 4.5191 10.1432 5.45095 10.1432 6.65495V8.77018H7.66927V11.64H10.1432V19H3.5625C2.58116 19 1.74208 18.6516 1.04525 17.9548C0.348416 17.2579 0 16.4188 0 15.4375V3.5625C0 2.58116 0.348416 1.74208 1.04525 1.04525C1.74208 0.348416 2.58116 0 3.5625 0H15.4375Z"
                    fill="white"
                    fill-opacity="0.7"
                  />
                </svg>
              </Link>
              <Link
                to="https://www.instagram.com/officialsalonrated/"
                target="_blank"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8112 0.750229H6.72253C5.15584 0.750229 3.65331 1.3726 2.54549 2.48041C1.43767 3.58823 0.815308 5.09076 0.815308 6.65745L0.815308 13.7461C0.815308 15.3128 1.43767 16.8153 2.54549 17.9232C3.65331 19.031 5.15584 19.6533 6.72253 19.6533H13.8112C15.3779 19.6533 16.8804 19.031 17.9882 17.9232C19.0961 16.8153 19.7184 15.3128 19.7184 13.7461V6.65745C19.7184 5.09076 19.0961 3.58823 17.9882 2.48041C16.8804 1.3726 15.3779 0.750229 13.8112 0.750229ZM17.9462 13.7461C17.9462 16.0263 16.0914 17.8812 13.8112 17.8812H6.72253C4.44234 17.8812 2.58747 16.0263 2.58747 13.7461V6.65745C2.58747 4.37726 4.44234 2.5224 6.72253 2.5224H13.8112C16.0914 2.5224 17.9462 4.37726 17.9462 6.65745V13.7461Z"
                    fill="white"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M10.2669 5.47601C9.01356 5.47601 7.81154 5.97391 6.92529 6.86016C6.03903 7.74642 5.54114 8.94844 5.54114 10.2018C5.54114 11.4551 6.03903 12.6572 6.92529 13.5434C7.81154 14.4297 9.01356 14.9276 10.2669 14.9276C11.5203 14.9276 12.7223 14.4297 13.6085 13.5434C14.4948 12.6572 14.9927 11.4551 14.9927 10.2018C14.9927 8.94844 14.4948 7.74642 13.6085 6.86016C12.7223 5.97391 11.5203 5.47601 10.2669 5.47601ZM10.2669 13.1554C9.48386 13.1545 8.73314 12.843 8.17943 12.2893C7.62573 11.7356 7.31424 10.9848 7.3133 10.2018C7.3133 8.57258 8.63888 7.24818 10.2669 7.24818C11.8949 7.24818 13.2205 8.57258 13.2205 10.2018C13.2205 11.8298 11.8949 13.1554 10.2669 13.1554Z"
                    fill="white"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M15.3466 5.75228C15.6944 5.75228 15.9763 5.47035 15.9763 5.12257C15.9763 4.77479 15.6944 4.49286 15.3466 4.49286C14.9988 4.49286 14.7169 4.77479 14.7169 5.12257C14.7169 5.47035 14.9988 5.75228 15.3466 5.75228Z"
                    fill="white"
                    fill-opacity="0.7"
                  />
                </svg>
              </Link>
              <Link
                to="https://www.linkedin.com/company/salon-rated"
                target="_blank"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5969 0H1.4039C0.628485 0 0 0.609267 0 1.36124V17.6381C0 18.3901 0.628696 19 1.4039 19H17.5969C18.3724 19 19 18.3899 19 17.6381V1.36124C19 0.609478 18.3724 0 17.5969 0ZM5.76023 15.9043H2.88951V7.32598H5.76023V15.9043ZM4.32508 6.1541H4.30587C3.34319 6.1541 2.71914 5.49564 2.71914 4.67147C2.71914 3.83061 3.36156 3.1901 4.34324 3.1901C5.32513 3.1901 5.92912 3.8304 5.94812 4.67147C5.94812 5.49586 5.32534 6.1541 4.32508 6.1541ZM16.1086 15.9043H13.2387V11.3147C13.2387 10.1612 12.8228 9.37439 11.785 9.37439C10.991 9.37439 10.5198 9.90533 10.3129 10.4177C10.236 10.6009 10.2173 10.8568 10.2173 11.1127V15.9041H7.3478C7.3478 15.9041 7.38538 8.13031 7.3478 7.32577H10.2175V8.54198C10.5985 7.95762 11.2792 7.12395 12.8034 7.12395C14.6922 7.12395 16.1086 8.34923 16.1086 10.9854V15.9043ZM10.1989 8.56921C10.204 8.56119 10.2107 8.55127 10.2175 8.54198V8.56921H10.1989Z"
                    fill="white"
                    fill-opacity="0.7"
                  />
                </svg>
              </Link>
            </div>
            <div className="Logo-top">
              <Link to="/">
                <img
                  className="white-logo"
                  src={require("../assets/images/logo-main.webp")}
                />
                <img
                  className="black-logo"
                  src={require("../assets/images/black-logo.webp")}
                />
              </Link>
            </div>
            <div className="login-top">
              <Link to="/Login">Login</Link>
            </div>
            <div className="toggle-icon" onClick={toggleClass}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 7H26ZM4 15H26ZM4 23H26Z" fill="black" />
                <path
                  d="M4 7H26M4 15H26M4 23H26"
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="navbar-bottom">
            <ul className={isActive ? "mobile-menu" : null}>
              <li>
                <Link to="/searchsalons">Search Salons</Link>
              </li>
              <li>
                <Link to="/werecommended">Recommended Brands</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
              <li>
                <Link to="/pricing">Salon Sign Up</Link>
              </li>

              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>

              <li className="login-btn">
                <Link to="/Login">Login</Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
