export const treatements = [
  {
    label: "All Treatments",
  },
  {
    label: "Body Treatments",
  },
  {
    label: "Brow Treatments",
  },
  {
    label: "Facial Treatments",
  },
  // {
  //   label: "Hair Salon",
  // },
  {
    label: "Hair Treatments",
  },
  {
    label: "Holistic Therapies",
  },
  {
    label: "Lash Treatments",
  },
  {
    label: "Nail Treatments",
  },
  // {
  //   label: "Tanning Salon",
  // },
  {
    label: "Waxing",
  },
    // {
  //   label: "Aesthetic Clinic",
  // },
  
];



export const brands = [
  {
    label: "Bio Sculpture",
  },
  {
    label: "HDBrows",
  },
  {
    label: "The Gel Bottle",
  },
  {
    label: "Wax One",
  },
  {
    label: "Starpil",
  },
  {
    label: "CND",
  },
  {
    label: "Saint & Serenity",
  },
  {
    label: "London Lash Pro",
  },
  {
    label: "Glossify",
  },
  {
    label: "OPI Nails",
  },
  {
    label: "Refectocil",
  },
  {
    label: "NAF! Stuff",
  },
  {
    label: "Lycon",
  },
  {
    label: "Elim",
  },
  {
    label: "Navy",
  },
  {
    label: "Wella",
  },
  {
    label: "Olaplex",
  },
  {
    label: "L'Oreal",
  },
  {
    label: "Nouveau Lashes",
  },
  {
    label: "Other",
  },
];