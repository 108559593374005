import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import SignUpTwo from "./pages/SignUpTwo";
import SignUpThree from "./pages/SignUpThree";
import SignUpFour from "./pages/SignUpFour";
import SignUpFive from "./pages/SignUpFive";
import Login from "./pages/Login";
import SearchSalons from "./pages/SearchSalons";
import ContactUs from "./pages/ContactUs";
import LiveChat from "./pages/LiveChat";
import SalonDetail from "./pages/SalonDetail";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import HelpSupport from "./pages/HelpSupport";
import LeaveReview from "./pages/LeaveReview";
import TermsUse from "./pages/TermsUse";
import OurStandard from "./pages/OurStandard";
import Careers from "./pages/Careers";
import PrivacyNotice from "./pages/PrivacyNotice";
import WhySalonRated from "./pages/WhySalonRated";
import ComplaintsMember from "./pages/ComplaintsMember";
import Dashboard from "./pages/Dashboard";
import Statistics from "./pages/Statistics";
import ReviewDetails from "./pages/ReviewDetails";
import YourPlan from "./pages/YourPlan";
import UserHelpSupport from "./pages/UserHelpSupport";
import AccountDetails from "./pages/AccountDetails";
import BusinessDetails from "./pages/BusinessDetails";
import ProfileDetails from "./pages/ProfileDetails";
import SocialMedia from "./pages/SocialMedia";
import Reviews from "./pages/Reviews";
import ForgotPassword from "./pages/ForgotPassword";
import ContactUsOut from "./pages/ContactUsOut";
import WeRecommended from "./pages/WeRecommended";
import PrivateRoute from "./routes/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import InitMap from "./Components/Map/InitMap";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { checkAllSalonExpires, getStatics, getSubscriptionById } from "./redux/actions/adminActions";
import ScrollToTop from "./utils/ScrollToTop";
import PaymentSuccess from "./pages/PaymentSuccess";
import MarketingPreferences from "./pages/MarketingPreferences";
import SignUpTime from "./pages/SignUpTime";
import OpeningHours from "./pages/OpeningHours";
import Pricing from "./pages/Pricing";
import { trackPageView } from "./pages/analytics";
import PasswordSecurity from "./pages/PasswordSecurity";

function App() {
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    dispatch(getStatics());
    if (sessionStorage.getItem("userId")) {
      dispatch(getSubscriptionById(sessionStorage.getItem("userId")));
    }
    dispatch(checkAllSalonExpires());
  }, []);

  useEffect(() => {
    const currentUrl = window.location.pathname + window.location.search;
    ReactGA.pageview(currentUrl);
  }, []);

  return (
    <div className="App">
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signuptwo/:id" element={<SignUpTwo />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/signupthree/:id" element={<SignUpThree />} />
            <Route path="/signupfour/:id" element={<SignUpFour />} />
            <Route path="/signupfive/:id" element={<SignUpFive />} />
            <Route path="/signup-time/:id" element={<SignUpTime />} />

            <Route path="/Login" element={<Login />} />
            <Route
              path="/payment/:id/:subscription"
              element={<PaymentSuccess />}
            />
            <Route path="/passwordReset/:token" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/map" element={<InitMap />} />
            <Route path="/salon/:id" element={<SalonDetail />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogdetail/:id" element={<BlogDetail />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/werecommended" element={<WeRecommended />} />
            <Route path="/whysalonrated" element={<WhySalonRated />} />
            <Route path="/complaintsmember" element={<ComplaintsMember />} />
            <Route path="/termsuse" element={<TermsUse />} />
            <Route path="/helpsupport" element={<HelpSupport />} />
            <Route path="/our-standard" element={<OurStandard />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/privacynotice" element={<PrivacyNotice />} />
            <Route path="/leavereview/:id" element={<LeaveReview />} />
            <Route element={<PrivateRoute />}>
              <Route path="/livechat" element={<LiveChat />} />

              <Route path="/blog" element={<Blog />} />
              <Route path="/blogdetail" element={<BlogDetail />} />
              <Route path="/helpsupport" element={<HelpSupport />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/reviewdetails/:id" element={<ReviewDetails />} />
              <Route path="/yourplan" element={<YourPlan />} />
              <Route path="/userhelpsupport" element={<UserHelpSupport />} />
              <Route path="/accountdetails" element={<AccountDetails />} />
              <Route path="/openinghours" element={<OpeningHours />} />
              <Route path="/businessdetails" element={<BusinessDetails />} />
              <Route path="/profiledetails" element={<ProfileDetails />} />
              <Route
                path="/marketing-preferences"
                element={<MarketingPreferences />}
              />
              <Route path="/socialmedia" element={<SocialMedia />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/contactusout" element={<ContactUsOut />} />
            </Route>
            <Route path="/searchsalons" element={<SearchSalons />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/password-security" element={<PasswordSecurity />} />
          </Routes>
        </ScrollToTop>
    </div>
  );
}

export default App;
