import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Layout/Layout";
import {Helmet} from "react-helmet";
export default function Careers() {
  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 10);
  //   });
  // }, []);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
     <Helmet>
        <title>Careers - Salon Rated</title>
        <meta name="description" content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK" />
    </Helmet>
      <Layout>
        {/* Header section start  */}
        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>
        {/* Header section End */}
        {/* Careers start */}
        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">Careers</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={10} className="mx-auto">
                <div className="Careers-box">
                  <p>
                    As an ever-expanding team, we are always on the lookout for
                    like-minded team members — whether it be in{" "}
                    <b>
                      public relations, customer care coordination, or
                      pot-of-tea perfectionists.
                    </b>{" "}
                    We need you!
                  </p>
                  <p>
                    <b>Register your interest</b> by simply sending us an email
                    to say hello, along with a recent copy of your CV and your
                    reasons for believing that SalonRated is the right workplace
                    for you.
                  </p>
                  <p className="email-box">
                    <b>Email: hello@salonrated.com</b>
                  </p>
                  <p className="mt-3">
                    We will aim to reply within 2 working days.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*  Careers end */}
        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
